import React, { useEffect, useState } from "react";
import StatCardContainer from "../components/reports/stat-card-container";
import VoterReportsSection from "../components/reports/voters";
import {
  MDBRow,
  MDBIcon,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import VoterSurveyReportsSection from "../components/reports/surveys";
import VoterSurveySummaryReportsSection from "../components/reports/surveys/voters-survey-summary-reports";
import SurveyerTableReportContainer from "../components/reports/surveyers/surveyers-table-container";
import {
  fetchVotersReportByConstituency,
  fetchVotersSummaryCards,
} from "../../services/report-service";
import { getActiveSurvey } from "../../services/voter-survey-service";
import AdvancedVoterSurveyReportsSection from "../components/reports/surveys/advanced-survey-report";

const Reports = ({ user }) => {
  const [iconsActive, setIconsActive] = useState("tab1");
  const [votersReport, setVotersReport] = useState({ totalVoters: 0 });
  const [activeSurvey, setActiveSurvey] = useState(null);
  const [tileReport, setTileReport] = useState({
    totalVoters: 0,
    totalSurveyers: 0,
    totalSurveyed: 0,
  });

  const fetchVotersReportsData = async (orgId) => {
    let votersReport = await fetchVotersReportByConstituency(
      orgId,
      282,
      () => {},
      () => {}
    );
    if (votersReport && votersReport._id) {
      setVotersReport(votersReport);
    }
    let activeSurvey = await getActiveSurvey(
      user.organizationId,
      () => {},
      () => {}
    );
    if (activeSurvey && activeSurvey._id) {
      setActiveSurvey(activeSurvey);
      // await getActiveSurvey(()=>{},()=>{})
      fetchVotersTileReportsData(user.organizationId, activeSurvey._id);
    }
  };

  const fetchVotersTileReportsData = async (orgId, surveyId) => {
    let tileReport = await fetchVotersSummaryCards(
      orgId,
      surveyId,
      () => {},
      () => {}
    );
    if (tileReport) {
      setTileReport(tileReport);
    }
  };

  useEffect(() => {
    if (user && user.organizationId) {
      fetchVotersReportsData(user.organizationId);
    }
  }, [user]);

  const handleIconsClick = (value) => {
    if (value === iconsActive) {
      return;
    }

    setIconsActive(value);
  };

  return (
    <>
      <MDBRow>
        <StatCardContainer tileReport={tileReport} />
      </MDBRow>
      <MDBTabs className="mb-3">
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleIconsClick("tab1")}
            active={iconsActive === "tab1"}
          >
            <MDBIcon fas icon="chart-pie" className="me-2" /> Voters Report
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleIconsClick("tab2")}
            active={iconsActive === "tab2"}
          >
            <MDBIcon fas icon="chart-line" className="me-2" />
            Surveyer Report
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleIconsClick("tab3")}
            active={iconsActive === "tab3"}
          >
            <MDBIcon fas icon="chart-bar" className="me-2" /> Survey Summary
            Report
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleIconsClick("tab4")}
            active={iconsActive === "tab4"}
          >
            <MDBIcon fas icon="chart-line" className="me-2" /> Survey Report
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleIconsClick("tab5")}
            active={iconsActive === "tab5"}
          >
            <MDBIcon fas icon="chart-line" className="me-2" /> Advanced Survey Report
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={iconsActive === "tab1"}>
          <VoterReportsSection votersReport={votersReport} />
        </MDBTabsPane>
        <MDBTabsPane open={iconsActive === "tab2"}>
          <SurveyerTableReportContainer
            survey={activeSurvey}
            organizationId={user.organizationId}
          />
        </MDBTabsPane>
        <MDBTabsPane open={iconsActive === "tab3"}>
          <VoterSurveySummaryReportsSection survey={activeSurvey}/>
        </MDBTabsPane>
        <MDBTabsPane open={iconsActive === "tab4"}>
          <VoterSurveyReportsSection survey={activeSurvey}/>
        </MDBTabsPane>
        <MDBTabsPane open={iconsActive === "tab5"}>
          <AdvancedVoterSurveyReportsSection survey={activeSurvey}/>
        </MDBTabsPane>
      </MDBTabsContent>
    </>
  );
};

export default Reports;
