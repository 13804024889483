import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
export default function PublicAdvancedSearchCard(props) {
  const [user, setUser] = useState({ constituencies: [], booths: [] });
  const [searchType, setSearchType] = useState(1);
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("voter-user"));
    setUser(userData);
  }, []);
  return (
    <MDBCard
      shadow="0"
      border="light"
      background="white"
      className="mt-3 mb-2 w-100 search-card-container"
    >
      <MDBCardBody className="d-web">
        <Row>
          <Col md={6} xs={12} className="mt-2">
            <InputGroup>
              <Input
                className="input-group-with-icon"
                placeholder="पहिले नाव"
                value={props.search.firstName}
                onChange={(e) => {
                  props.setPagination({ isNext: true, page: 0 });
                  props.setSearch({
                    ...props.search,
                    firstName: e.target.value,
                    page: 0,
                  });
                }}
              />
            </InputGroup>
          </Col>
          <Col md={6} xs={12} className="mt-2">
            <InputGroup>
              <Input
                className="input-group-with-icon"
                placeholder="आडनाव"
                value={props.search.lastName}
                onChange={(e) => {
                  props.setPagination({ isNext: true, page: 0 });
                  props.setSearch({
                    ...props.search,
                    lastName: e.target.value,
                    page: 0,
                  });
                }}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12} className="mt-2">
            <InputGroup>
              <Input
                className="input-group-with-icon"
                placeholder="पत्ता"
                value={props.search.address}
                onChange={(e) => {
                  props.setPagination({ isNext: true, page: 0 });
                  props.setSearch({
                    ...props.search,
                    address: e.target.value,
                    page: 0,
                  });
                }}
              />
            </InputGroup>
          </Col>
          <Col md={6} xs={12} className="mt-2">
            <InputGroup>
              <Input
                className="input-group-with-icon"
                placeholder="मतदार आयडी"
                value={props.search.voterId}
                onChange={(e) => {
                  props.setPagination({ isNext: true, page: 0 });
                  props.setSearch({
                    ...props.search,
                    voterId: e.target.value,
                    page: 0,
                  });
                }}
              />
            </InputGroup>
          </Col>
        </Row>
      </MDBCardBody>
      <MDBCardBody className="d-mobile">
        <Row>
          <Col>
            <div className="inline-check">
              <FormGroup check>
                <Input
                  type="radio"
                  checked={searchType == 1}
                  onChange={() => {
                    setSearchType(1);
                    props.setPagination({ isNext: true, page: 0 });
                    props.setSearch({
                      ...props.search,
                      voterId: "",
                      firstName: "",
                      lastName: "",
                      address: "",
                      page: 0,
                    });
                  }}
                />

                <Label
                  check
                  onClick={() => {
                    if (searchType == 2) {
                      {
                        setSearchType(1);
                        props.setPagination({ isNext: true, page: 0 });
                        props.setSearch({
                          ...props.search,
                          firstName: "",
                          lastName: "",
                          address: "",
                          voterId: "",
                          page: 0,
                        });
                      }
                    } else {
                      setSearchType(1);
                    }
                  }}
                >
                  मतदार आयडीने शोधा
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  type="radio"
                  checked={searchType == 2}
                  onChange={() => {
                    setSearchType(2);
                    props.setPagination({ isNext: true, page: 0 });
                    props.setSearch({
                      ...props.search,
                      firstName: "",
                      lastName: "",
                      address: "",
                      voterId: "",
                      page: 0,
                    });
                  }}
                />

                <Label
                  check
                  onClick={() => {
                    if (searchType == 1) {
                      {
                        setSearchType(2);
                        props.setPagination({ isNext: true, page: 0 });
                        props.setSearch({
                          ...props.search,
                          firstName: "",
                          lastName: "",
                          address: "",
                          voterId: "",
                          page: 0,
                        });
                      }
                    } else {
                      setSearchType(2);
                    }
                  }}
                >
                  नावाने शोधा
                </Label>
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row>
          {searchType == 1 ? (
            <Col md={6} xs={12} className="mt-2">
              <InputGroup>
                <Input
                  className="input-group-with-icon"
                  placeholder="मतदार आयडी"
                  value={props.search.voterId}
                  onChange={(e) => {
                    props.setPagination({ isNext: true, page: 0 });
                    props.setSearch({
                      ...props.search,
                      voterId: e.target.value,
                      page: 0,
                    });
                  }}
                />
              </InputGroup>
            </Col>
          ) : (
            ""
          )}

          {searchType == 2 ? (
            <>
              <Col md={6} xs={12} className="mt-2">
                <InputGroup>
                  <Input
                    className="input-group-with-icon"
                    placeholder="पहिले नाव"
                    value={props.search.firstName}
                    onChange={(e) => {
                      props.setPagination({ isNext: true, page: 0 });
                      props.setSearch({
                        ...props.search,
                        firstName: e.target.value,
                        page: 0,
                      });
                    }}
                  />
                </InputGroup>
              </Col>
              <Col md={6} xs={12} className="mt-2">
                <InputGroup>
                  <Input
                    className="input-group-with-icon"
                    placeholder="आडनाव"
                    value={props.search.lastName}
                    onChange={(e) => {
                      props.setPagination({ isNext: true, page: 0 });
                      props.setSearch({
                        ...props.search,
                        lastName: e.target.value,
                        page: 0,
                      });
                    }}
                  />
                </InputGroup>
              </Col>

              <Col md={6} xs={12} className="mt-2">
                <InputGroup>
                  <Input
                    className="input-group-with-icon"
                    placeholder="पत्ता"
                    value={props.search.address}
                    onChange={(e) => {
                      props.setPagination({ isNext: true, page: 0 });
                      props.setSearch({
                        ...props.search,
                        address: e.target.value,
                        page: 0,
                      });
                    }}
                  />
                </InputGroup>
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
      </MDBCardBody>
    </MDBCard>
  );
}
