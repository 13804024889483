import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import HeaderImage from "../components/header-image";
import PublicSearchSection from "../components/public-search-section";
import PublicTopbar from "../layout/public-topbar";
function SearchApp(props) {
  return (
    <div className="main-content">
      <PublicTopbar />
      <div className="content">
        <MDBContainer>
          <MDBRow>
            <MDBCol sm="12" lg="10" offsetMd="1">
              <HeaderImage />
              <PublicSearchSection />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
}

export default SearchApp;
