import React, { useEffect, useState } from "react";
import SearchCard from "./search-card";
import ResultCard from "./result-card";
import { advancedSearchUser, fetchConstituency, searchUser } from "../services/search-service";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import AdvancedSearchCard from "./advanced-search-card";
const SearchSection = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState({
    constituency: 282,
    booths: [],
    searchKey: "",
    page: 0,
  });
  const [advanceSearch, setAdvanceSearch] = useState({
    // constituency: 282,
    firstName: "",
    lastName: "",
    address:"",
    voterId:"",
    page: 0,
  });

  const [pagination, setPagination] = useState({
    isNext: true,
    page: 0,
  });

  const [constituency, setConstituency] = useState({
    PART_NO: [],
    name1: "",
    name2: "",
    AC_NO: 282,
  });

  const fetchConstituencyData = async () => {
    let data = await fetchConstituency(
      search.constituency,
      () => {},
      () => {}
    );
    setConstituency(data);
  };

  const handlePagination = (action) => {
    if (pagination.isNext && action == "next") {
      setPagination({ isNext: true, page: pagination.page + 1 });
      setSearch({ ...search, page: pagination.page + 1 });
    }
    if (pagination.page > 0 && action == "prev") {
      setPagination({ isNext: true, page: pagination.page - 1 });
      setSearch({ ...search, page: pagination.page - 1 });
    }
  };

  const handlePaginationForAdvanced = (action) => {
    if (pagination.isNext && action == "next") {
      setPagination({ isNext: true, page: pagination.page + 1 });
      setAdvanceSearch({ ...advanceSearch, page: pagination.page + 1 });
    }
    if (pagination.page > 0 && action == "prev") {
      setPagination({ isNext: true, page: pagination.page - 1 });
      setAdvanceSearch({ ...advanceSearch, page: pagination.page - 1 });
    }
  };

  useEffect(() => {
    fetchConstituencyData(search.constituency);
  }, [search.constituency]);

  const [voters, setVoters] = useState([]);
  useEffect(() => {
    console.log(search);
    if (search.searchKey != "") {
      const fetchData = async () => {
        let data = await searchUser(
          search,
          () => {},
          (message) => {
            if (message == "401") {
              localStorage.clear();
              navigate("/login");
            }
          }
        );
        if (data.totalPages<=pagination.page+1) {
          setVoters(data.hits);

          setPagination({ ...pagination, isNext: false });
        } else {
          setPagination({ ...pagination, isNext: true });
          setVoters(data.hits);
        }
      };
      const debounceFetchData = setTimeout(fetchData, 500); // Debounce API call by 300ms

      return () => clearTimeout(debounceFetchData);
    } else {
      setVoters([]);
    }
  }, [search]);


  useEffect(() => {
    console.log(advanceSearch);
    if (advanceSearch.firstName != ""||advanceSearch.lastName != "" ||advanceSearch.address != ""||advanceSearch.voterId != "") {
      const fetchData = async () => {
        let data = await advancedSearchUser(
          advanceSearch,
          () => {},
          (message) => {
            if (message == "401") {
              localStorage.clear();
              navigate("/login");
            }
          }
        );
        if (data.totalPages<=pagination.page+1) {
          setVoters(data.hits);

          setPagination({ ...pagination, isNext: false });
        } else {
          setPagination({ ...pagination, isNext: true });
          setVoters(data.hits);
        }
      };
      const debounceFetchData = setTimeout(fetchData, 500); // Debounce API call by 300ms

      return () => clearTimeout(debounceFetchData);
    } else {
      setVoters([]);
    }
  }, [advanceSearch]);
  return (
    <div>
      <AdvancedSearchCard
        setSearch={setAdvanceSearch}
        setPagination={setPagination}
        search={advanceSearch}
      />
      <div className="result-section">
        {voters.map((voter, index) => (
          <ResultCard
            voter={voter}
            key={index}
            onClick={() => navigate("/voter/" + voter.voterId)}
          />
        ))}
        {voters.length == 0 ? (
          <p className="pagination-buttons">मतदार सापडत नाही!</p>
        ) : (
          ""
        )}

        <Row className="pagination-buttons">
          <Col>
            <Button
              color="secondary"
              outline
              block
              disabled={pagination.page == 0 }
              onClick={() => handlePaginationForAdvanced("prev")}
            >
              Prev
            </Button>
          </Col>

          <Col>
            <Button
              color="secondary"
              block
              outline
              disabled={!pagination.isNext || voters.length == 0}
              onClick={() => handlePaginationForAdvanced("next")}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchSection;
