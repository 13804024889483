import { customFetch } from "./base-fetch";
import { api_base_url } from "./baseurl";

export const fetchVotersReportByConstituency = (
  orgId,
  constId,
  successHandler,
  errorHandler
) => {
  return customFetch(
    `${api_base_url}/reports/summary/${orgId}/${constId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const fetchUsersWithSurveyCounts = (
  orgId,
  surveyId,
  page,
  pageSize,
  sortField,
  sortOrder,
  successHandler,
  errorHandler
) => {
  return customFetch(
    `${api_base_url}/reports/surveyers-users?organizationId=${orgId}&surveyId=${surveyId}&page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const fetchVotersSummaryCards = (
  orgId,
  surveyId,
  successHandler,
  errorHandler
) => {
  return customFetch(
    `${api_base_url}/reports/summary-tile-report?organizationId=${orgId}&surveyId=${surveyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const fetchVotersSurveySummaryReport = (
  surveyId,
  successHandler,
  errorHandler
) => {
  return customFetch(
    `${api_base_url}/reports/voter-survey-summary?surveyId=${surveyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const fetchVotersSurveyQuestionWiseReport = (
  surveyId,
  successHandler,
  errorHandler
) => {
  return customFetch(
    `${api_base_url}/reports/voter-survey-question-report?surveyId=${surveyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

// export const downloadVoters = (
//   surveyId,
//   criteria,
//   successHandler,
//   errorHandler
// ) => {
//   return customFetch(
//     `${api_base_url}/reports/voter-list-excel?surveyId=${surveyId}`,
//     {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem(
//           "voter-user-access-token"
//         )}`,
//       },
//       body: JSON.stringify(criteria),
//     },
//     successHandler,
//     errorHandler
//   );
// };

export const downloadVoters = async (surveyId, criteria,fields) => {
  const response = await fetch(
    `${api_base_url}/reports/voter-list-excel?surveyId=${surveyId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify({criteria,fields}),
    }
  );

  // Check if the response is ok
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  // Convert response to blob
  const blob = await response.blob();

  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create an anchor element and trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = "voter_list.xlsx";
  document.body.appendChild(a);
  a.click();

  // Remove the anchor element from the DOM
  document.body.removeChild(a);

  // Revoke the URL
  window.URL.revokeObjectURL(url);
};

export const downloadPDFVoters = async (surveyId, criteria,fields) => {
  const response = await fetch(
    `${api_base_url}/reports/voter-list-pdf?surveyId=${surveyId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify({criteria,fields}),
    }
  );

  // Check if the response is ok
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  // Convert response to blob
  const blob = await response.blob();

  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create an anchor element and trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = "voter_list.pdf";
  document.body.appendChild(a);
  a.click();

  // Remove the anchor element from the DOM
  document.body.removeChild(a);

  // Revoke the URL
  window.URL.revokeObjectURL(url);
};
