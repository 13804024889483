import { MDBIcon, MDBTypography } from "mdb-react-ui-kit";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import {
  deleteVoterSurvey,
  getAllSurveys,
  getVoterSurveyBySurveyerId,
  getVoterSurveyBySurveyerIdAndSurveyId,
} from "../../services/voter-survey-service";
import ResultCard from "../../components/result-card";
import SurveyModal from "../../components/survey-modal";
import ConfirmationModal from "../components/modals/confirmation-modal";
import UserSelect from "../components/users/user-select";
import { fetchUsers } from "../../services/admin-service";

const Surveys = ({ user }) => {
  const [surveysList, setSurveysList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [activeSurvey, setActiveSurvey] = useState(null);
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [surveys, setAllSurveys] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser,setSelectedUser] = useState(false)
  const [pagination, setPagination] = useState({
    isNext: true,
    page: 0,
  });

  const [survey, selectedSurvey] = useState(null);
  const [isSurveyModalShow, setShowSurveyModal] = useState(null);
  const confirmationModalToggle = () =>
    setConfirmationModal(!confirmationModal);
  const fetchAllSurveysData = useCallback(async () => {
    let allSurveys = await getAllSurveys(
      user.organizationId,
      () => {},
      () => {}
    );
    if (allSurveys && allSurveys.length) {
      setAllSurveys(allSurveys);
      setActiveSurvey(allSurveys[0]);
      // await getActiveSurvey(()=>{},()=>{})
    }
  }, [user.organizationId]);
  useEffect(() => {
    fetchAllSurveysData(user.organizationId);
    fetchUsersList(user.organizationId)
  }, [user.organizationId]);

  const handlePage = (pageNo) => {
    setPage(pageNo);
  };
  const handlePagination = (action) => {
    if (page < totalPages && action == "next") {
      // setPagination({ isNext: true, page: pagination.page + 1 });
      setPage(page + 1);
      // setSearch({ ...search, page: pagination.page + 1 });
    }
    if (page > 1 && action == "prev") {
      // setPagination({ isNext: true, page: pagination.page - 1 });
      setPage(page - 1);
      // setSearch({ ...search, page: pagination.page - 1 });
    }
  };
  const handleEditClick = (survey) => {
    // ToDo: Redirect to survey details page

    selectedSurvey(survey);
    setShowSurveyModal(true);
  };

  const handleDeleteClick = async (survey) => {
    // ToDo: If required open confirmation modal
    setConfirmationModal(true);
    setSurveyToDelete(survey.surveyId);
    // handleDeleteSurvey(survey);
  };

  const handleConfirmationModalClosed = () => {
    setSurveyToDelete(null);
  };

  const handleDeleteSurvey = async () => {
    let result = await deleteVoterSurvey(
      surveyToDelete,
      () => {},
      () => {}
    );
    setSurveyToDelete(null);
    fetchSurveys();
  };

  const fetchUsersList = async () => {
    if (user && user._id) {
      let usersListData = await fetchUsers(
        user.organizationId,
        () => {},
        () => {}
      );

      if (usersListData && Array.isArray(usersListData)) {
        setUsersList(usersListData);
      }
    }
  };


  const fetchSurveys = async (user) => {
    let result = await getVoterSurveyBySurveyerIdAndSurveyId(
      user._id,
      activeSurvey._id,
      page,
      limit,
      () => {},
      () => {}
    );

    if (result && result.totalCount >= 0) {
      setSurveysList(result.data);
    }
    setTotalCount(result.totalCount);
    setTotalPages(result.totalPages);
  };

  useEffect(() => {
    console.log("activeSurvey", activeSurvey);
    if (
      selectedUser &&
      selectedUser._id &&
      activeSurvey &&
      activeSurvey._id &&
      activeSurvey._id != ""
    ) {
      fetchSurveys(selectedUser);
    }
  }, [selectedUser, page, activeSurvey]);

  return (
    <>
      <Row>
        <Col>
          <MDBTypography variant="h3" tag="strong">
            My Surveys
          </MDBTypography>
          <MDBTypography>{totalCount} surveys filled</MDBTypography>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="exampleSelect">Surveys</Label>
            <InputGroup>
              <InputGroupText style={{ height: "auto" }}>
                <MDBIcon fas icon="person" />
              </InputGroupText>
              <Input
                placeholder="Relation"
                value={activeSurvey && activeSurvey._id ? activeSurvey._id : ""}
                onChange={(e) => {
                  let surveysInfo = surveys.filter(
                    (survey) => survey._id == e.target.value
                  );
                  if (surveysInfo.length) {
                    setActiveSurvey(surveysInfo[0]);
                  }
                }}
                name="select"
                type="select"
              >
                <option
                  value={""}
                  disabled={activeSurvey && activeSurvey._id != ""}
                >
                  Select
                </option>
                {surveys.map((survey) => (
                  <option value={survey._id}>{survey.name}</option>
                ))}
              </Input>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col>
          <UserSelect
            users={usersList}
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
          />
        </Col>
      </Row>

      <Row className="pagination-buttons">
        <Col>
          <Button
            color="secondary"
            outline
            block
            disabled={page == 1}
            onClick={() => handlePagination("prev")}
          >
            Prev
          </Button>
        </Col>

        <Col>
          <Button
            color="secondary"
            block
            outline
            disabled={page == totalPages || surveysList.length == 0}
            onClick={() => handlePagination("next")}
          >
            Next
          </Button>
        </Col>
      </Row>
      <div className="table-responsive survey-table">
        <MDBTable align="middle">
          <MDBTableHead>
            <tr>
              <th scope="col">Voter</th>
              <th scope="col">Actions</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {surveysList.map((survey) => (
              <tr key={survey.id}>
                <td>
                  <ResultCard voter={survey.voter} />
                  {/* <p className="fw-bold mb-0">{survey.name}</p>
                  <p className="text-muted mb-0">{survey.subtitle}</p> */}
                </td>
                <td>
                  <MDBBtn
                    color="link"
                    rounded
                    size="sm"
                    onClick={() => handleEditClick(survey)}
                  >
                    Edit
                  </MDBBtn>
                  <MDBBtn
                    color="link"
                    rounded
                    size="sm"
                    onClick={() => handleDeleteClick(survey)}
                  >
                    Delete
                  </MDBBtn>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
      <SurveyModal
        voterId={survey?.voter?._id}
        surveyId={activeSurvey?._id}
        modal={isSurveyModalShow}
        fetchSurveyData={() => {}}
        setShowModal={setShowSurveyModal}
      />
      <ConfirmationModal
        isOpen={confirmationModal}
        toggle={confirmationModalToggle}
        title={"Confirm Delete"}
        description={"Are you sure you want to delete this survey?"}
        positiveButtonText={"Delete"}
        onConfirm={handleDeleteSurvey}
        onClosed={handleConfirmationModalClosed}
      />
    </>
  );
};
export default Surveys;
