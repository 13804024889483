import { useState, useEffect } from "react";

const usePagination = (data, initialPageSize) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    let filteredData = data;

    if (searchTerm) {
      filteredData = filteredData.filter((item) =>
        Object.values(item).some((val) =>
          val.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (sortKey) {
      filteredData.sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortKey] > b[sortKey] ? 1 : -1;
        } else {
          return a[sortKey] < b[sortKey] ? 1 : -1;
        }
      });
    }

    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    setCurrentData(filteredData.slice(start, end));
  }, [currentPage, data, pageSize, searchTerm, sortKey, sortOrder]);

  const totalPages = Math.ceil(data.length / pageSize);

  const setRowsPerPage = (rows) => {
    setPageSize(rows);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  return {
    currentPage,
    totalPages,
    currentData,
    setCurrentPage,
    rowsPerPage: pageSize,
    setRowsPerPage,
    searchTerm,
    setSearchTerm,
    sortKey,
    setSortKey,
    sortOrder,
    setSortOrder,
  };
};

export default usePagination;
