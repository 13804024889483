import React, { useEffect, useState } from "react";

import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import VoterReligionPie from "./voter-religion-pie";
import VoterReligionGenderWiseChart from "./voter-religion-gender-bar";
import VoterGenderPie from "../voters/voter-gender-pie";
import VoterAgeWiseChart from "../voters/voter-age-bar";
import VoterSurveySummary from "./voter-survey-summary";
import { religions } from "../../../../components/religions";
import { professions } from "../../../../components/profession-constant";
import VoterQuestion from "./voter-question";
import { relationsSurvererMap } from "../../../../components/relations-constant";
import {
  downloadPDFVoters,
  downloadVoters,
  fetchVotersSurveyQuestionWiseReport,
} from "../../../../services/report-service";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import FieldSelectionComponent from "./field-selection-component";

const questionsData = {
  1: {
    id: 1,
    text: "संपर्क झाला",
    field: "isContacted",
    isMultiple: false,
    isBoolean: true,
    isDisabled: false,
  },
  2: {
    id: 2,
    text: "मयत",
    field: "isDead",
    isMultiple: false,
    isBoolean: true,
    isDisabled: false,
  },
  3: {
    id: 3,
    text: "कुटुंब प्रमुख",
    field: "isHead",
    isMultiple: false,
    isBoolean: true,
    isDisabled: false,
  },
  4: {
    id: 4,
    text: "लाभार्थी",
    field: "isBeneficiary",
    isMultiple: false,
    isBoolean: true,
    isDisabled: false,
  },
  5: {
    id: 5,
    text: "Gender",
    field: "gender",
    isMultiple: true,
    isBoolean: false,
    values: ["Male", "Female", "Transgender", "Other"],
    tvalues: ["m", "f", "t", "o"],
    isDisabled: false,
  },
  6: {
    id: 6,
    text: "Age Group",
    field: "ageGroup",
    isMultiple: true,
    isBoolean: false,
    values: ["18-25", "26-35", "36-45", "46-60", "60+"],
    isDisabled: false,
  },

  7: {
    id: 7,
    text: "मतदान व्यवस्थेत कल",
    field: "voterPreference",
    isMultiple: true,
    isBoolean: false,
    values: ["A", "B", "C"],
    isDisabled: false,
  },
  8: {
    id: 8,
    text: "धर्म",
    field: "religion",
    isMultiple: true,
    isBoolean: false,
    values: [...religions.map((religion) => religion.religion)],
    isDisabled: false,
  },
  9: {
    id: 9,
    text: "जात",
    field: "caste",
    isMultiple: true,
    isBoolean: false,
    isDependent: true,
    parent: "7",
    values: [],
    isDisabled: true,
  },
  10: {
    id: 10,
    text: "स्थलांतरित",
    field: "isRelocated",
    isMultiple: false,
    isBoolean: true,
    isDisabled: false,
  },
  11: {
    id: 11,
    text: "Relocated To",
    field: "relocatedTo",
    isMultiple: true,
    isBoolean: false,
    tvalues: ["InDistrict", "OutDistrict"],
    values: ["जिल्ह्यामध्ये", "जिल्ह्याबाहेर"],
    isDisabled: true,
  },
  12: {
    id: 12,
    text: "व्यवसाय",
    field: "occupation",
    isMultiple: true,
    isBoolean: false,
    values: [
      "माहित नाही",
      ...professions.map((profession) => profession.marathi),
    ],
    isDisabled: false,
  },
  // {
  //   id: 5,
  //   text: "Relation With Surveyer",
  //   field: "relationToSurveyer",
  //   isMultiple: true,
  //   isBoolean: false,
  //   values: [...relationsSurvererMap.map((relation) => relation.marathi)],
  // },
};

const surveyData = {
  isContacted: 0,
  isDead: 0,
  isHead: 0,
  isBeneficiary: 0,
  isRelocated: 0,
  voterPreference: 0,
  relationToSurveyer: 0,
  religion: 0,
  caste: 0,
  relocatedTo: 0,
  occupation: 0,
  gender: 0,
  ageGroup: 0,
};
const AdvancedVoterSurveyReportsSection = ({ survey }) => {
  const [questions, setQuestions] = useState(questionsData);

  const [criteria, setCriteria] = useState({});
  const [surveyReport, setSurveyReport] = useState(surveyData);
  const [selectedFields, setSelectedFields] = useState([
    "POLLING_STATION_ADR2",
    "PART_NO",
    "isBeneficiary",
    "voterPreference",
    "isDead",
    "phoneNumber",
    "whatsappNumber",
    "occupation",
    "religion",
    "caste",
    "isRelocated",
    "relocatedTo",
    "EPIC_NO",
    "FM_NAME_V1",
    "LASTNAME_V1",
    "GENDER",
    "AGE",
    "adr2",
    "AC_NO",
    "SLNOINPART",
  ]);

  const downloadExcel = async (survyeId) => {
    let result = await downloadVoters(
      survyeId,
      criteria,
      selectedFields,
      () => {},
      () => {}
    );
    // if (result && result._id) {
    //   setSurveyReport(result);
    // }
  };

  const downloadPdf = async (survyeId) => {
    let result = await downloadPDFVoters(
      survyeId,
      criteria,
      selectedFields,
      () => {},
      () => {}
    );
    // if (result && result._id) {
    //   setSurveyReport(result);
    // }
  };
  const reset = async () => {
    setQuestions(questionsData);
    setCriteria({});
    setSurveyReport(surveyData);
  };
  useEffect(() => {
    let criteria = {};
    Object.keys(surveyReport).forEach((key) => {
      if (surveyReport[key] != 0) {
        criteria[key] = surveyReport[key];
      }
    });
    setCriteria(criteria);
  }, [surveyReport]);
  return (
    <>
      <MDBRow className="justify-content-center">
        {Object.keys(questions).map((key) => (
          <MDBCol md="2" className="">
            <FormGroup>
              <Label for="exampleSelect"> {questions[key].text}</Label>
              <Input
                id="exampleSelect"
                name="select"
                type="select"
                disabled={questions[key].isDisabled}
                value={surveyReport[questions[key].field]}
                onChange={(e) => {
                  if (key == 10) {
                    if (e.target.value != 1) {
                      setSurveyReport({
                        ...surveyReport,
                        [questions[key].field]: e.target.value,
                        relocatedTo: 0,
                      });
                      setQuestions({
                        ...questions,
                        11: { ...questions[11], isDisabled: true },
                      });
                    } else {
                      setSurveyReport({
                        ...surveyReport,
                        [questions[key].field]: e.target.value,
                        relocatedTo: 0,
                      });
                      setQuestions({
                        ...questions,
                        [11]: { ...questions[11], isDisabled: false },
                      });
                    }
                  } else if (key == 8) {
                    if (e.target.value != 0) {
                      setSurveyReport({
                        ...surveyReport,
                        [questions[key].field]: e.target.value,
                        caste: 0,
                      });
                      let castes = religions.filter(
                        (religion) => religion.religion == e.target.value
                      )[0].caste;
                      setQuestions({
                        ...questions,
                        9: {
                          ...questions[9],
                          isDisabled: false,
                          values: [...castes],
                        },
                      });
                    } else {
                      setSurveyReport({
                        ...surveyReport,
                        [questions[key].field]: e.target.value,
                        caste: 0,
                      });

                      setQuestions({
                        ...questions,
                        [9]: { ...questions[9], isDisabled: true, values: [] },
                      });
                    }
                  } else {
                    setSurveyReport({
                      ...surveyReport,
                      [questions[key].field]: e.target.value,
                    });
                  }
                }}
              >
                {questions[key].isBoolean ? (
                  <>
                    <option value={0}>सर्व</option>
                    <option value={1}>हो</option>
                    <option value={2}>नाही</option>
                  </>
                ) : questions[key].isMultiple ? (
                  <>
                    <option value={0}>सर्व</option>
                    {questions[key].values.map((value, index) => (
                      <option
                        value={
                          questions[key].id == 11 || questions[key].id == 5
                            ? questions[key].tvalues[index]
                            : value
                        }
                      >
                        {value}
                      </option>
                    ))}
                  </>
                ) : null}
              </Input>
            </FormGroup>
          </MDBCol>
        ))}
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <FieldSelectionComponent
            setSelectedFields={setSelectedFields}
            selectedFields={selectedFields}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol>
          <Button
          className="mr-3"
            color="primary"
            // disabled={surveyRelationStatus.isDisabled}
            onClick={() => {
              downloadExcel(survey._id);
            }}
          >
            Download Excel
          </Button>

          {/* <Button
            color="primary"
             disabled={surveyRelationStatus.isDisabled}
            onClick={() => {
              downloadPdf(survey._id);
            }}
          >
            Download PDF
          </Button> */}

          <Button color="secondary" onClick={reset}>
            Reset
          </Button>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default AdvancedVoterSurveyReportsSection;
