import React, { useState } from "react";

import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBRow,
} from "mdb-react-ui-kit";
import ConfirmationModal from "../admin/components/modals/confirmation-modal";
import MyProfileModal from "../admin/components/modals/my-profile-modal";

const Topbar = ({ toggleSidebar, user }) => {
  const [topbarCollapse, setTopbarCollapse] = useState(false);
  const toggleTopbar = () => setTopbarCollapse(!topbarCollapse);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [logoutconfirmationModal, setLogoutConfirmationModal] = useState(false);
  const [myProfileModal, setMyProfileModal] = useState(false);
  const navigate = useNavigate();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const logoutConfirmationModalToggle = () =>
    setLogoutConfirmationModal(!logoutconfirmationModal);
  const myProfileModalToggle = () => setMyProfileModal(!myProfileModal);

  const handleLogout = () => {
    // Handle Logout here
    localStorage.clear();
    navigate("/");
    setLogoutConfirmationModal(false);
  };

  const handleMyProfileChanges = () => {
    // Handle changes in my profile here
    setMyProfileModal(false);
  };

  const handleMyProfileModalClosed = () => {
    // if required, use this to do something when MyProfileModal gets closed by
    // either by clicking on cancel button or toggled using state
  };

  return (
    <>
      <Navbar className="navbar p-2 ps-0 bg-white" expand="lg" container="lg">
        <MDBNavbarBrand href="/" style={{ fontWeight: 500 }}>
          <img
            width="30"
            style={{ objectFit: "contain", marginRight: 15 }}
            src={require("../assets/images/logo/Antridge_Icon_PNG.png")}
          />
          Proxima Voters
        </MDBNavbarBrand>
        <NavbarToggler onClick={toggleTopbar} />
        <Collapse
          isOpen={topbarCollapse}
          navbar
          className="justify-content-end"
        >
          <Nav navbar>
            <MDBNavbarNav className="ms-3">
              <MDBNavbarItem className="me-2">
                <MDBNavbarLink
                  active
                  aria-current="page"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate("/");
                  }}
                >
                  Search
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink
                  active
                  aria-current="page"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate("/admin");
                  }}
                >
                  Dashboard
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </Nav>
          <div>
            <Nav className="ms-auto">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                direction={"down"}
              >
                <DropdownToggle nav caret>
                  {user ? user.name : "NA"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setMyProfileModal(true)}>
                    Account Settings
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setLogoutConfirmationModal(true)}
                  >
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </div>
        </Collapse>
      </Navbar>
      <ConfirmationModal
        isOpen={logoutconfirmationModal}
        toggle={logoutConfirmationModalToggle}
        title={"Logout"}
        description={"Are you sure you want to logout?"}
        positiveButtonText={"Logout"}
        onConfirm={handleLogout}
      />
      <MyProfileModal
        toggle={myProfileModalToggle}
        isOpen={myProfileModal}
        onSave={handleMyProfileChanges}
        onClosed={handleMyProfileModalClosed}
        user={user}
      />
    </>
  );
};

export default Topbar;
