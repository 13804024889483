import React, { useEffect, useState } from "react";
import SurveyersReportTable from "./surveyer-table-report";
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { fetchUsersWithSurveyCounts } from "../../../../services/report-service";

const SurveyerTableReportContainer = ({ survey, organizationId }) => {
  const sortOptions = [
    { label: "Name", value: "name" },
    // { label: "Roles", value: "roleId" },
    { label: "Total Surveys", value: "totalSurveys" },
  ];

  const [data, setData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState("totalSurveys");
  const [sortOrder, setSortOrder] = useState("desc");

  const fetchSurveyerReport = async (orgId, surveyId) => {
    let tileReport = await fetchUsersWithSurveyCounts(
      orgId,
      surveyId,
      page,
      pageSize,
      sortField,
      sortOrder,
      () => {},
      () => {}
    );
    if (tileReport) {
      setData(tileReport.data);
      setTotalUsers(tileReport.totalCount);
    }
  };

  useEffect(() => {
    if (survey && survey._id && organizationId) {
      fetchSurveyerReport(organizationId, survey._id);
    }
  }, [survey, organizationId]);

  useEffect(() => {
   
    if (survey && survey._id && organizationId) {
      fetchSurveyerReport(organizationId, survey._id);
    }
  }, [page, pageSize, sortOrder, sortField]);

  return (
    <MDBRow className="justify-content-center">
      <MDBCol md="12" className="mb-3">
        <MDBCard>
          <MDBCardBody>
            <SurveyersReportTable
              data={data}
              rolesList={[]}
              sortOptions={sortOptions}
              totalUsers={totalUsers}
              options={{
                setPageSize,
                setSortField,
                setSortOrder,
                setPage,
                page,
                totalPages: Number(Math.floor(totalUsers / pageSize)),
                pageSize,
                sortOrder,
                sortField,
              }}
            />
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
};

export default SurveyerTableReportContainer;
