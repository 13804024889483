import React from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBBadge,
} from "mdb-react-ui-kit";
import SearchAndSort from "../../tables/SearchAndSort";
import usePagination from "../../../hooks/usePagination";
import Pagination from "../../tables/pagination";
import ServerPagination from "../../tables/server-pagination";

const SurveyersReportTable = ({ data, rolesList, sortOptions,options }) => {
  const pageSize = 10;
  const {
    currentPage,
    totalPages,
    currentData,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    searchTerm,
    setSearchTerm,
    sortKey,
    setSortKey,
    sortOrder,
    setSortOrder,
  } = usePagination(data, pageSize);

  return (
    <>
      <SearchAndSort
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        sortKey={options.sortField}
        setSortKey={options.setSortField}
        sortOrder={options.sortOrder}
        setSortOrder={options.setSortOrder}
        sortOptions={sortOptions}
      />
      <div className="table-responsive">
        <MDBTable align="middle">
          <MDBTableHead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Role</th>
              <th scope="col">Total Surveys</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {data.map((report) => (
              <tr key={report.id}>
                <td>
                  <div className="d-flex align-items-center">
                    {/* <img
                      src={noPhoto}
                      alt=""
                      style={{ width: "45px", height: "45px" }}
                      className="rounded-circle"
                    /> */}
                    <div className="ms-3">
                      <p className="fw-bold mb-0">{report.name}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="fw-normal mb-0">
                    {rolesList.filter((role) => report.roleId == role._id).length
                      ? rolesList.filter((role) => report.roleId == role._id)[0]
                          .role
                      : "Not Found"}
                  </p>
                  {/* <p className="text-muted mb-0">IT department</p> */}
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="ms-3">
                      <p className="fw-bold mb-0">{report.totalSurveys}</p>
                    </div>
                  </div>
                </td>

                {/* <td>Senior</td> */}
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
      <ServerPagination
        currentPage={options.page}
        totalPages={options.totalPages}
        setCurrentPage={options.setPage}
        rowsPerPage={options.pageSize}
        setRowsPerPage={options.setPageSize}
      />
    </>
  );
};

export default SurveyersReportTable;
