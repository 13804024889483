import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  getRelations,
  getVoterSurveyBySurveyId,
} from "../services/voter-survey-service";
import AddRelativeWithSurveyModal from "./add-relative-with-survey";
import FamilyMemberCard from "./family-member-card";
import SurveyModal from "./survey-modal";
import VoterDetailCard from "./voter-detail-card";
import AddRelativeModal from "./add-relative-modal";
import AddRelativeAdvancedModal from "./add-relative-modal-advanced";
export default function VoterSurvey(props) {
  const [user, setUser] = useState({
    constituencies: [],
    booths: [],
    isDead: false,
    isFamilyHead: false,
  });
  const [relations, setRelations] = useState([]);
  const [relationsIds, setRelationsIds] = useState([]);
  const [isModalShow, setShowModal] = useState(false);
  const [isSurveyModalShow, setShowSurveyModal] = useState(false);
  const navigate = useNavigate();
  const [voterSurvey, setVoterSurvey] = useState({
    religion: "",
    caste: "",
    isDead: false,
    isFamilyHead: false,
    isContacted: false,
    voterPreference: "",
    phoneNumber: "",
    whatsappNumber: "",
    notes: "",
    occupation: "",
    occupationType: "",
    isRelocated: false,
    relocatedTo: "",
    relocatedPlace: "",
    relations: [],
  });

  // const [relations, setRelations] = useState([]);
  const addRelation = (relation) => {
    let relations = [...voterSurvey.relations];
    relations.push(relation);
    console.log(relations);
    setVoterSurvey({ ...voterSurvey, relations });
  };
  const removeRelation = (voterId) => {
    let relationsData = voterSurvey.relations.filter(
      (relation) => relation.voterId != voterId
    );
    setVoterSurvey({ ...voterSurvey, relations: relationsData });
  };

  const fetchSurveyData = async () => {
    let voterSurveyData = await getVoterSurveyBySurveyId(
      props.voter._id,
      props.survey._id,
      () => {},
      () => {}
    );

    if (voterSurveyData && voterSurveyData._id) {
      setVoterSurvey(voterSurveyData);
    } else {
      setVoterSurvey({
        religion: "",
        caste: "",
        isDead: false,
        isFamilyHead: false,
        isContacted: false,
        voterPreference: "",
        phoneNumber: "",
        whatsappNumber: "",
        notes: "",
        occupation: "",
        isRelocated: false,
        relocatedTo: "",
        relocatedPlace: "",
        relations: [],
        voterId: props.voter._id,
        surveyId: props.survey._id,
      });
    }
  };
  useEffect(() => {
    if (props.survey && props.survey._id && props.voter && props.voter._id) {
      fetchSurveyData();
    }
  }, [props.survey, props.voter]);

  const fetchRelationsData = async () => {
    let relationsData = await getRelations(
      props.voter._id,
      props.orgId,
      () => {},
      () => {}
    );

    if (relationsData && relationsData.voterId) {
      setRelations(relationsData.relations);
    
    } else {
      setRelations([]);
    }
  };

  useEffect(() => {
    if (props.voter && props.voter._id) {
      fetchRelationsData();
    }
  }, [props.voter]);

  useEffect(()=>{
    let ids =[]
    relations.forEach((relation)=>{
      if(relation&&relation.voterId!=""){
        ids.push(relation.voterId)
      }
     
    })
    if(ids.indexOf(props.voter._id)==-1){
      ids.push(props.voter._id)
    }
    setRelationsIds(ids)
  },[relations])

  return (
    <MDBCard
      shadow="0"
      border="light"
      background="white"
      className="mt-0 w-100 voter-survey"
    >
      <VoterDetailCard voter={props.voter} />
      <MDBCardBody>
        {!voterSurvey.isFamilyHead &&
        relations.length &&
        relations[0].headId != props.voter?._id ? (
          ""
        ) : (
          <Row className={"mt-4"}>
            <Col className="family-head" sm={12} md={8}>
              <FormGroup switch>
                <Label check>कुटुंब प्रमुख</Label>
                <Input
                  type="switch"
                  checked={
                    voterSurvey.isFamilyHead ||
                    (relations.length &&
                      relations[0].headId == props.voter?._id)
                  }
                  disabled={relations.length}
                  onClick={() => {
                    if (relations.length == 0) {
                      setVoterSurvey({
                        ...voterSurvey,
                        isFamilyHead: !voterSurvey.isFamilyHead,
                      });
                    }
                  }}
                />
              </FormGroup>
              {voterSurvey.isFamilyHead ||
              (relations.length && relations[0].headId == props.voter?._id) ? (
                <Button
                  color="primary"
                  className=" ml-1 mt-0 ms-3"
                  onClick={() => setShowModal(true)}
                >
                  Add Family Member
                </Button>
              ) : (
                ""
              )}
            </Col>
            <Col className="button-right-group" md={4} sm={12}>
              <Button
                color="primary"
                className=" ml-3 mr-1 mt-0"
                onClick={() => setShowSurveyModal(true)}
              >
                {voterSurvey && voterSurvey._id ? "Edit Survey" : "Add Survey"}
              </Button>
            </Col>
          </Row>
        )}
        <Row className={"mt-3"}>
          <Col>
            {relations.length != 0 ? <Label check>कुटुंब</Label> : <></>}
            <div className="">
              {relations.map((relation, index) => {
                return (
                  <FamilyMemberCard
                    voter={relation.voter}
                    relationHead={relation.headId}
                    relation={
                      relation.headId == relation.voter._id
                        ? "कुटुंब प्रमुख"
                        : relation.relationName
                    }
                    relationId={relation._id}
                    fetchRelationsData={fetchRelationsData}
                    surveyId={props.survey?._id}
                    key={index}
                    voterSurveyData={voterSurvey}
                    onClick={() => {}}
                    user={user}
                  />
                );
              })}
            </div>
          </Col>
        </Row>
      </MDBCardBody>
      {/* <AddRelativeWithSurveyModal
        modal={isModalShow}
        voter={props.voter}
        setShowModal={setShowModal}
        addRelation={addRelation}
        orgId={props.orgId}
        fetchRelationsData={fetchRelationsData}
        surveyId={props.survey?._id}
        fetchSurveyData={fetchSurveyData}
        user={user}
      /> */}
       <AddRelativeAdvancedModal
        modal={isModalShow}
        voter={props.voter}
        setShowModal={setShowModal}
        addRelation={addRelation}
        orgId={props.orgId}
        fetchRelationsData={fetchRelationsData}
        surveyId={props.survey?._id}
        fetchSurveyData={fetchSurveyData}
        user={user}
        relationIds={relationsIds}
      />
      <SurveyModal
        voterId={props.voter?._id}
        surveyId={props.survey?._id}
        modal={isSurveyModalShow}
        fetchSurveyData={fetchSurveyData}
        setShowModal={setShowSurveyModal}
      />
    </MDBCard>
  );
}
