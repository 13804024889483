import { MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import RoleDetailsModal from "../components/modals/role-details-modal";
import ConfirmationModal from "../components/modals/confirmation-modal";
import { fetchRoles } from "../../services/admin-service";

const Roles = () => {
  const [roleDetailsModal, setRoleDetailsModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [user, setUser] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const roleDetailsModalToggle = () => setRoleDetailsModal(!roleDetailsModal);
  const confirmationModalToggle = () =>
    setConfirmationModal(!confirmationModal);

  const handleAddRoleClick = () => {
    setIsEdit(false);
    setSelectedRole(null);
    setRoleDetailsModal(true);
  };

  const handleEditClick = (role) => {
    setSelectedRole(role);
    setIsEdit(true);
    setRoleDetailsModal(true);
  };

  const handleDeleteClick = (role) => {
    setConfirmationModal(true);
    setSelectedRole(role);
  };

  const handleSave = (roleToSave) => {
    // Handle form submission, e.g., call an API to add the user
    if (isEdit) {
      setRolesList(
        rolesList.map((role) => (role.id === roleToSave.id ? roleToSave : role))
      );
    } else {
      setRolesList([...rolesList, { ...roleToSave, id: rolesList.length + 1 }]);
    }
    setIsEdit(false);
    setSelectedRole(null);
  };

  const handleDeleteRole = () => {
    setConfirmationModal(false);
    setRolesList(rolesList.filter((role) => role.id !== selectedRole.id));
  };

  const handleRoleDetailsModalClosed = () => {
    setIsEdit(false);
    setSelectedRole(null);
  };

  const handleConfirmationModalClosed = () => {
    setSelectedRole(null);
  };

  const fetchRolesData = async () => {
    let data = await fetchRoles(
      user.organizationId,
      () => {},
      () => {}
    );
    if (data && data.length) {
      setRolesList(data);
    }
  };

  useEffect(() => {
    if (user && user._id) {
      fetchRolesData();
    }
  }, [user]);
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("voter-user"));
    setUser(userData);
  }, []);

  return (
    <>
      <Row>
        <Col>
          <MDBTypography variant="h3" tag="strong">
            Roles
          </MDBTypography>
          <MDBTypography>{rolesList.length} Roles</MDBTypography>
        </Col>
        <Col className="text-end align-content-center">
          <Button color="primary" onClick={() => handleAddRoleClick()}>
            Add Role
          </Button>
        </Col>
      </Row>

      <div className="table-responsive">
        <MDBTable align="middle">
          <MDBTableHead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Permissions</th>
              <th scope="col">Actions</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {rolesList.map((role) => (
              <tr key={role.id}>
                <td>
                  <p className="fw-bold mb-0">{role.role}</p>
                </td>
                <td>
                  <p className="fw-normal mb-0">{"ToDo"}</p>
                </td>
                <td>
                  <MDBBtn
                    color="link"
                    rounded
                    size="sm"
                    onClick={() => handleEditClick(role)}
                  >
                    Edit
                  </MDBBtn>
                  <MDBBtn
                    color="link"
                    rounded
                    size="sm"
                    onClick={() => handleDeleteClick(role)}
                  >
                    Delete
                  </MDBBtn>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
      <RoleDetailsModal
        role={selectedRole}
        toggle={roleDetailsModalToggle}
        isOpen={roleDetailsModal}
        onSave={handleSave}
        isEdit={isEdit}
        orgId={user.organizationId}
        fetchRolesData={fetchRolesData}
        onClosed={handleRoleDetailsModalClosed}
      />
      <ConfirmationModal
        isOpen={confirmationModal}
        toggle={confirmationModalToggle}
        title={"Confirm Delete"}
        description={"Are you sure you want to delete this role?"}
        positiveButtonText={"Delete"}
        onConfirm={handleDeleteRole}
        onClosed={handleConfirmationModalClosed}
      />
    </>
  );
};
export default Roles;
