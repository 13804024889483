import React, { useState } from "react";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";

const VoterSurveyFields = [
  { label: "Survey ID", value: "surveyId" },
  { label: "संपर्क झाला", value: "isContacted" },
  { label: "लाभार्थी", value: "isBeneficiary" },
  { label: "मतदान व्यवस्थेत कल", value: "voterPreference" },
  { label: "मयत", value: "isDead" },
  { label: "कुटुंब प्रमुख", value: "isFamilyHead" },
  { label: "मोबाईल नंबर", value: "phoneNumber" },
  { label: "मोबाईल नंबर(WhatsApp)", value: "whatsappNumber" },
  { label: "टिप्पणी", value: "notes" },
  { label: "व्यवसाय", value: "occupation" },
  { label: "धर्म", value: "religion" },
  { label: "जात", value: "caste" },
  { label: "स्थलांतरित", value: "isRelocated" },
  { label: "स्थलांतरित ठिकाण ", value: "relocatedTo" },
  { label: "स्थलांतरित जागा", value: "relocatedPlace" },
  //   { label: 'Primary Surveyer Name', value: 'primarySurveyer.name' },
  //   { label: 'Primary Surveyer Relation', value: 'primarySurveyer.relationToSurveyer' },
  //   { label: 'Primary Surveyer Action', value: 'primarySurveyer.action' },
  //   { label: 'Primary Surveyer ID', value: 'primarySurveyer.id' },
  { label: "Created At", value: "created_at" },
  { label: "Updated At", value: "updated_at" },
];

const VoterFields = [
  { label: "वोटिंग कार्ड", value: "EPIC_NO" },
  { label: "नाव", value: "FM_NAME_V1" },
  { label: "आडनाव ", value: "LASTNAME_V1" },
  { label: "Gender", value: "GENDER" },
  { label: "वय", value: "AGE" },
  { label: "पत्ता", value: "adr2" },
  { label: "मतदानाचे ठिकाण", value: "POLLING_STATION_ADR2" },
  { label: "मतदारसंघ", value: "AC_NO" },
  { label: "बूथ क्र", value: "PART_NO" },
  { label: "यादिभाग", value: "SECTION_NO" },
  { label: "अ.क्रं.", value: "SLNOINPART" },
];

const FieldSelectionComponent = ({ selectedFields, setSelectedFields }) => {
  const handleChange = (selected) => {
    setSelectedFields(selected);
  };

  return (
    <div>
      <h6>Select Fields for Report</h6>
      <DualListBox
        options={[...VoterSurveyFields, ...VoterFields]}
        selected={selectedFields}
        onChange={handleChange}
        canFilter
      />
    </div>
  );
};

export default FieldSelectionComponent;
