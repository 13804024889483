import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VoterDetailCard from "./voter-detail-card";
import HeaderImage from "./header-image";
function VoterCardModal(props) {
  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle} centered>
        <ModalHeader>
          <HeaderImage isVoter={true} />
        </ModalHeader>
        <ModalBody>
          <VoterDetailCard voter={props.voter} />
        </ModalBody>
      </Modal>
    </div>
  );
}

export default VoterCardModal;
