import React from "react";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import { FormGroup, Input, Label } from "reactstrap";

const ServerPagination = ({
  currentPage,
  totalPages,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  // Extra validation to avoid invalid page numbers
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <MDBPagination className="mb-0 mt-4 justify-content-end">
      {/* This tag can be div. Setting it to MDBPaginationItem just for style */}
      <MDBPaginationItem
        disabled
        className="d-flex text-nowrap align-items-center"
      >
        <MDBPaginationLink>Rows per page:</MDBPaginationLink>
        <Input
          id="rowsPerPageSelect"
          name="select"
          type="select"
          bsSize="sm"
          className="me-3"
          value={rowsPerPage}
          onChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={200}>200</option>
        </Input>
      </MDBPaginationItem>
      <MDBPaginationItem disabled={currentPage === 1}>
        <MDBPaginationLink
          previous={currentPage === 1 ? "true" : "false"}
          // href="#"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </MDBPaginationLink>
      </MDBPaginationItem>
      {Array.from(Array(totalPages).keys())
        .filter((page) => page < currentPage + 3 && page > currentPage - 3)
        .map((page, index) => (
          <MDBPaginationItem active={currentPage === page + 1} key={page}>
            <MDBPaginationLink
              // href="#"
              onClick={() => setCurrentPage(page + 1)}
            >
              {page + 1}
            </MDBPaginationLink>
          </MDBPaginationItem>
        ))}
      <MDBPaginationItem disabled={currentPage === totalPages}>
        <MDBPaginationLink
          next={currentPage === totalPages ? "true" : "false"}
          // href="#"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </MDBPaginationLink>
      </MDBPaginationItem>
    </MDBPagination>
  );
};

export default ServerPagination;
