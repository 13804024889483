import { customFetch } from "./base-fetch";
import { api_base_url } from "./baseurl";

export const getVoter = (voterId, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/voters/voter/${voterId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const getVoterSurveyLatest = (voterId, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/voter-surveys/voter/${voterId}/latest`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const getVoterSurveyBySurveyId = (
  voterId,
  surveyId,
  successHandler,
  errorHandler
) => {
  return customFetch(
    `${api_base_url}/voter-surveys/voter/${voterId}/${surveyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const getAllSurveys = (orgId, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/surveys/surveys/${orgId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const getActiveSurvey = (orgId, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/surveys/active/${orgId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const saveVoterSurvey = (survey, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/voter-surveys`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify(survey),
    },
    successHandler,
    errorHandler
  );
};

export const updateVoterSurvey = (survey, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/voter-surveys/${survey._id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify(survey),
    },
    successHandler,
    errorHandler
  );
};

export const deleteVoterSurvey = (surveyId, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/voter-surveys/${surveyId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const addRelation = (relation, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/relations`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify(relation),
    },
    successHandler,
    errorHandler
  );
};

export const getRelations = (voterId, orgId, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/relations/voter-relation/${voterId}/${orgId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const removeRelation = (id, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/relations/remove/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const getVoterSurveyBySurveyerIdAndSurveyId = (
  surveyerId,
  surveyId,
  page,
  limit,
  successHandler,
  errorHandler
) => {
  return customFetch(
    `${api_base_url}/voter-surveys/voters-by-surveyer/${surveyId}/${surveyerId}?page=${page}&limit=${limit}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};
