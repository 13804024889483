import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import HeaderImage from "../components/header-image";
import SearchSection from "../components/search-section";
import Navbar from "../layout/navbar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Topbar from "../layout/topbar";
function Dashboard(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !localStorage.getItem("voter-user-access-token") ||
      localStorage.getItem("voter-user-access-token") == ""
    ) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="main-content">
      <Topbar user={props.user}/>
      <div className="content">
        <MDBContainer>
          <MDBRow>
            <MDBCol sm="12" lg="10" offsetMd="1">
              <HeaderImage />
              <SearchSection />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
}

export default Dashboard;
