import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
  MDBIcon,
} from "mdb-react-ui-kit";
import {
  FormGroup,
  Label,
  Badge,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import logo from "../assets/logo123.png";
export default function AdvancedSearchCard(props) {
  const [user, setUser] = useState({ constituencies: [], booths: [] });
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("voter-user"));
    setUser(userData);
  }, []);
  return (
    <MDBCard
      shadow="0"
      border="light"
      background="white"
      className="mt-3 mb-2 w-100 search-card-container"
    >
      <MDBCardBody>
        <Row>
          <Col md={6} xs={12}  className="mt-2">
            <InputGroup>
              <Input
                className="input-group-with-icon"
                placeholder="पहिले नाव"
                value={props.search.firstName}
                onChange={(e) => {
                  props.setPagination({ isNext: true, page: 0 });
                  props.setSearch({
                    ...props.search,
                    firstName: e.target.value,
                    page: 0,
                  });
                }}
              />
            </InputGroup>
          </Col>
          <Col md={6} xs={12} className="mt-2">
            <InputGroup>
              <Input
                className="input-group-with-icon"
                placeholder="आडनाव"
                value={props.search.lastName}
                onChange={(e) => {
                  props.setPagination({ isNext: true, page: 0 });
                  props.setSearch({
                    ...props.search,
                    lastName: e.target.value,
                    page: 0,
                  });
                }}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row >
          <Col md={6} xs={12} className="mt-2">
            <InputGroup>
              <Input
                className="input-group-with-icon"
                placeholder="पत्ता"
                value={props.search.address}
                onChange={(e) => {
                  props.setPagination({ isNext: true, page: 0 });
                  props.setSearch({
                    ...props.search,
                    address: e.target.value,
                    page: 0,
                  });
                }}
              />
            </InputGroup>
          </Col>
          <Col md={6} xs={12} className="mt-2">
            <InputGroup>
              <Input
                className="input-group-with-icon"
                placeholder="मतदार आयडी"
                value={props.search.voterId}
                onChange={(e) => {
                  props.setPagination({ isNext: true, page: 0 });
                  props.setSearch({
                    ...props.search,
                    voterId: e.target.value,
                    page: 0,
                  });
                }}
              />
            </InputGroup>
          </Col>
        </Row>
      </MDBCardBody>
    </MDBCard>
  );
}
