import { MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import VoterAgeWiseChart from "./voter-age-bar";
import VoterGenderPie from "./voter-gender-pie";

const VoterReportsSection = ({votersReport}) => {
  return (
    <>
      <MDBRow className="justify-content-center">
        <MDBCol md="6" className="mb-3">
          <MDBCard>
            <MDBCardBody>
              <VoterGenderPie votersReport={votersReport}/>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <VoterAgeWiseChart votersReport={votersReport}/>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      {/* <MDBRow className="justify-content-center">
        <MDBCol md="6">
          <VoterReligionPie />
        </MDBCol>
        <MDBCol md="6">
          <VoterReligionGenderWiseChart />
        </MDBCol>
      </MDBRow> */}
    </>
  );
};

export default VoterReportsSection;
