export const professions = [
  { english: "Homemaker", marathi: "गृहिणी" },
  { english: "Worker", marathi: "कामगार" },
  { english: "Job", marathi: "नोकरी" },
  { english: "Businessperson", marathi: "व्यवसायी" },
  { english: "Farmer", marathi: "शेतकरी" },
  { english: "Student", marathi: "विद्यार्थी" },
  { english: "Retired", marathi: "सेवानिवृत्त" },
  { english: "Unemployed", marathi: "बेरोजगार" },
  { english: "Professional", marathi: "व्यावसायिक" },
  { english: "Artist", marathi: "कलाकार" },
  { english: "Laborer", marathi: "मजूर" },
  { english: "Self-employed", marathi: "स्वयंरोजगार" },
  { english: "Teacher", marathi: "शिक्षक" },
  { english: "Engineer", marathi: "अभियंता" },
  { english: "Doctor", marathi: "डॉक्टर" },
  { english: "Lawyer", marathi: "वकील" },
  { english: "Government Employee", marathi: "शासकीय कर्मचारी" },
  { english: "Clerk", marathi: "लिपिक" },
  { english: "Technician", marathi: "तंत्रज्ञ" },
  { english: "Entrepreneur", marathi: "उद्योजक" },
  { english: "Craftsperson", marathi: "कारागीर" },
  { relation: "Other", marathi: "अन्य" },
];

const detailProfession = [
  [
    { english: "Doctor", marathi: "डॉक्टर" },
    { english: "Engineer", marathi: "अभियंता" },
    { english: "Teacher", marathi: "शिक्षक" },
    { english: "Lawyer", marathi: "वकील" },
    { english: "Farmer", marathi: "शेतकरी" },
    { english: "Nurse", marathi: "परिचारिका" },
    { english: "Accountant", marathi: "लेखापाल" },
    { english: "Artist", marathi: "कलाकार" },
    { english: "Mechanic", marathi: "यांत्रिक" },
    { english: "Electrician", marathi: "विद्युत तंत्रज्ञ" },
    { english: "Plumber", marathi: "नळकांडे" },
    { english: "Carpenter", marathi: "सुतार" },
    { english: "Blacksmith", marathi: "लोहार" },
    { english: "Fisherman", marathi: "कोळी" },
    { english: "Weaver", marathi: "विणकर" },
    { english: "Potter", marathi: "कुंभार" },
    { english: "Police Officer", marathi: "पोलीस अधिकारी" },
    { english: "Firefighter", marathi: "अग्निशमन कर्मचारी" },
    { english: "Pilot", marathi: "वैमानिक" },
    { english: "Scientist", marathi: "शास्त्रज्ञ" },
    { english: "Pharmacist", marathi: "औषधनिर्माता" },
    { english: "Dentist", marathi: "दंतवैद्य" },
    { english: "Architect", marathi: "वास्तुविशारद" },
    { english: "Chef", marathi: "स्वयंपाकी" },
    { english: "Journalist", marathi: "पत्रकार" },
    { english: "Photographer", marathi: "छायाचित्रकार" },
    { english: "Librarian", marathi: "ग्रंथपाल" },
    { english: "Veterinarian", marathi: "पशुवैद्य" },
    { english: "Banker", marathi: "बँक कर्मचारी" },
    { english: "Actor", marathi: "अभिनेता" },
    { english: "Actress", marathi: "अभिनेत्री" },
    { english: "Singer", marathi: "गायक" },
    { english: "Dancer", marathi: "नर्तक" },
    { english: "Writer", marathi: "लेखक" },
    { english: "Software Developer", marathi: "सॉफ्टवेअर विकसक" },
    { english: "Civil Servant", marathi: "नागरिक सेवक" },
    { english: "Soldier", marathi: "सैनिक" },
    { english: "Tailor", marathi: "शिंपी" },
    { english: "Driver", marathi: "चालक" },
    { english: "Painter", marathi: "चित्रकार" },
    { english: "Barber", marathi: "नाई" },
    { english: "Beautician", marathi: "सौंदर्यतज्ञ" },
    { english: "Receptionist", marathi: "स्वागतपाल" },
    { english: "Clerk", marathi: "लिपिक" },
    { english: "Businessperson", marathi: "व्यवसायी" },
    { english: "Entrepreneur", marathi: "उद्योजक" },
    { english: "Manager", marathi: "व्यवस्थापक" },
    { english: "Laborer", marathi: "मजूर" },
    { english: "Cleaner", marathi: "स्वच्छताकर्मी" },
    { english: "Gardener", marathi: "माळी" },
    { english: "Security Guard", marathi: "सुरक्षारक्षक" },
    { english: "Tailor", marathi: "शिंपी" },
    { english: "Priest", marathi: "पुरोहित" },
    { english: "Mason", marathi: "गवंडी" },
  ],
];
