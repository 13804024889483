import React from "react";
import {
  MDBCard,
  MDBCol,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBCardFooter,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

const DashboardCard = ({ title, subtitle, icon, buttonText, target }) => {
  const navigate = useNavigate();

  return (
    <>
      <MDBCol sm="5" className="mb-4">
        <MDBCard
          border="dark"
          background="white"
          shadow="0"
          className="border-opacity-25"
        >
          <MDBCardBody>
            <div className="d-flex">
              <img
                src={icon}
                alt=""
                style={{ width: "60px", height: "60px" }}
              />
              <div className="ms-4">
                <MDBCardTitle>{title}</MDBCardTitle>
                <MDBCardText>{subtitle}</MDBCardText>
              </div>
            </div>
          </MDBCardBody>
          <MDBCardFooter className="p-0 overflow-hidden border-top border-2">
            <MDBBtn
              onClick={() => {
                navigate(target);
              }}
              noRipple
              className="w-100 text-start p-3 ps-4"
              color="link"
            >
              <div className="d-flex justify-content-between">
                {buttonText}
                <MDBIcon className="me-2" fas icon="chevron-right" />
              </div>
            </MDBBtn>
          </MDBCardFooter>
        </MDBCard>
      </MDBCol>
    </>
  );
};

export default DashboardCard;
