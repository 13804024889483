import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { editUser } from "../../../services/admin-service";

const MyProfileModal = ({ user, toggle, isOpen, onSave, onClosed }) => {
  const [userData, setUserData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveUser();
  };

  const saveUser = async () => {
    if (userData._id && userData._id != "") {
      let result = await editUser(
        userData,
        () => {},
        () => {}
      );
      if(result && result._id){
        localStorage.setItem("voter-user", JSON.stringify(result));
      }
      
      console.log(result)
    }
    toggle();
  };

  useEffect(() => {
    setUserData(user);
  }, [user]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop={"static"}
        onClosed={onClosed}
      >
        <ModalHeader toggle={toggle}>Account Settings</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="firstName"> Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={userData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>

              {/* <Col md={6}>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={userData.lastName}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col> */}
            </Row>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={userData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="phoneNumber">Phone</Label>
              <Input
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                value={userData.phoneNumber}
                onChange={handleChange}
                required
              />
            </FormGroup>
            {/* <FormGroup>
              <Label for="role">Role</Label>
              <Input
                disabled
                type="text"
                name="role"
                id="role"
                value={userData.role}
                onChange={handleChange}
                required
              />
            </FormGroup> */}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    disabled
                    type="text"
                    name="username"
                    id="username"
                    value={userData.username}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    value={userData.password}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button color="primary" type="submit">
                Save
              </Button>{" "}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MyProfileModal;
