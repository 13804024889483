import { MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import UserDetailsModal from "../components/modals/user-details-modal";
import ConfirmationModal from "../components/modals/confirmation-modal";
import { fetchRoles, fetchUsers } from "../../services/admin-service";
import UsersTable from "../components/tables/users-table";

const Users = () => {
  const [userDetailsModal, setUserDetailsModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [user, setUser] = useState({});
  const userDetailsModalToggle = () => setUserDetailsModal(!userDetailsModal);
  const confirmationModalToggle = () =>
    setConfirmationModal(!confirmationModal);
  const sortOptions = [
    { label: "Name", value: "name" },
    { label: "Email", value: "email" },
    { label: "Role", value: "roleId" },
  ];

  const handleAddUserClick = () => {
    setIsEdit(false);
    setSelectedUser(null);
    setUserDetailsModal(true);
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setIsEdit(true);
    setUserDetailsModal(true);
  };

  const handleDeleteClick = (user) => {
    setConfirmationModal(true);
    setSelectedUser(user);
  };

  const handleSave = (userData) => {
    // Handle form submission, e.g., call an API to add the user
    if (isEdit) {
      setUsersList(
        usersList.map((user) => (user.id === userData.id ? userData : user))
      );
    } else {
      setUsersList([...usersList, { ...userData, id: usersList.length + 1 }]);
    }
    setIsEdit(false);
    setSelectedUser(null);
  };

  const handleDeleteUser = () => {
    setConfirmationModal(false);
    setUsersList(usersList.filter((user) => user.id !== selectedUser.id));
  };

  const handleUserDetailsModalClosed = () => {
    setIsEdit(false);
    setSelectedUser(null);
  };

  const handleConfirmationModalClosed = () => {
    setSelectedUser(null);
  };

  const fetchRolesData = async () => {
    let data = await fetchRoles(
      user.organizationId,
      () => {},
      () => {}
    );
    if (data && data.length) {
      setRolesList(data);
    }
  };

  const fetchUsersList = async () => {
    if (user && user._id) {
      let usersListData = await fetchUsers(
        user.organizationId,
        () => {},
        () => {}
      );

      if (usersListData && Array.isArray(usersListData)) {
        setUsersList(usersListData);
      }
    }
  };

  useEffect(() => {
    fetchUsersList();
    fetchRolesData();
  }, [user]);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("voter-user"));
    setUser(userData);
  }, []);
  return (
    <>
      <Row>
        <Col>
          <MDBTypography variant="h3" tag="strong">
            Users
          </MDBTypography>
          <MDBTypography>{usersList.length} Users</MDBTypography>
        </Col>
        <Col className="text-end align-content-center">
          <Button color="primary" onClick={() => handleAddUserClick()}>
            Add User
          </Button>
        </Col>
      </Row>

      <UsersTable
        data={usersList}
        rolesList={rolesList}
        onEditClick={handleEditClick}
        onDeleteClick={handleDeleteClick}
        sortOptions={sortOptions}
      />

      <UserDetailsModal
        user={selectedUser}
        toggle={userDetailsModalToggle}
        isOpen={userDetailsModal}
        onSave={handleSave}
        isEdit={isEdit}
        onClosed={handleUserDetailsModalClosed}
        rolesList={rolesList}
        fetchUsersList={fetchUsersList}
        orgId={user.organizationId}
      />
      <ConfirmationModal
        isOpen={confirmationModal}
        toggle={confirmationModalToggle}
        title={"Confirm Delete"}
        description={"Are you sure you want to delete this user?"}
        positiveButtonText={"Delete"}
        onConfirm={handleDeleteUser}
        onClosed={handleConfirmationModalClosed}
      />
    </>
  );
};
export default Users;
