import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
} from "mdb-react-ui-kit";
import logo from "../assets/header-image-search.jpg";
export default function HeaderImage() {
  return (
    <MDBCard shadow="0" border="light" background="white" className="w-100">
      <MDBRipple
        rippleColor="light"
        rippleTag="div"
        className="bg-image hover-overlay w-100"
      >
        <MDBCardImage src={logo} className="w-100" fluid alt="..." />
        <a>
          <div
            className="mask"
            style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
          ></div>
        </a>
      </MDBRipple>
    </MDBCard>
  );
}
