import {
  MDBRow,
  MDBTypography
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import DashboardCard from "../components/home/dashboard-card";

const AdminHome = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("voter-user"));
    setUser(userData);
  }, []);

  return (
    <>
      <Row>
        <Col>
          <MDBTypography variant="h3" tag="strong">
            Welcome {user.name}!
          </MDBTypography>
          <MDBTypography>Manage your account</MDBTypography>
        </Col>
      </Row>

      <MDBRow className="mt-4">
        <DashboardCard
          title={"User Management"}
          subtitle={
            "Manage user accounts, roles, and monitor user activity effortlessly."
          }
          icon={
            "https://app.antridge.com/static/media/ic_org_dashboard_user_roles.13000cef.svg"
          }
          buttonText={"Manage Users"}
          target={"/admin/users"}
        />
        <DashboardCard
          title={"Survey Statistics"}
          subtitle={
            "Track survey performance and response metrics in real-time."
          }
          icon={
            "https://app.antridge.com/static/media/ic_org_dashboard_security.9c2ae75e.svg"
          }
          buttonText={"Manage Surveys"}
          target={"/admin/my-surveys"}
        />
        <DashboardCard
          title={"Reports"}
          subtitle={
            "Generate and view detailed reports on survey participation and outcomes."
          }
          icon={
            "https://app.antridge.com/static/media/ic_org_dashboard_info.19bd053d.svg"
          }
          buttonText={"View Reports"}
          target={"/admin/reports"}
        />
      </MDBRow>
    </>
  );
};
export default AdminHome;
