import classNames from "classnames";
import React, { useEffect, useState } from "react";
import "./admin.css";
import Content from "./layout/content/content";
import Topbar from "./layout/content/topbar";
import SideBar from "./layout/sidebar/sidebar";
const AdminApp = ({user}) => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it initially to set the correct state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* <Navbar /> */}
      <div className="admin-container">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} user={user} />
        <div
          className={classNames("main-content", { "is-open": sidebarIsOpen })}
        >
          <Topbar toggleSidebar={toggleSidebar} user={user} />
          <Content
            toggleSidebar={toggleSidebar}
            sidebarIsOpen={sidebarIsOpen}
            user={user}
          />
        </div>
      </div>
    </>
  );
};

export default AdminApp;
