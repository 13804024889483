import React, { useState } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
export default function Navbar(props) {
  const [openBasic, setOpenBasic] = useState(false);
  const navigate = useNavigate();
  return (
    <MDBNavbar
      color="light"
      light
      className="navbar p-2 ps-0 bg-white"
      expand="md"
    >
      <MDBContainer>
        <MDBContainer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <MDBNavbarBrand href="/" style={{ fontWeight: 500 }}>
            <img
              width="30"
              style={{ objectFit: "contain", marginRight: 15 }}
              src={require("../assets/images/logo/Antridge_Icon_PNG.png")}
            />
            Proxima Voters
          </MDBNavbarBrand>
          {props.isShowBackButton ? (
            <MDBBtn onClick={() => navigate("/")}>Go To Search</MDBBtn>
          ) : (
            ""
          )}
          {/* <MDBNavbarToggler
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setOpenBasic(!openBasic)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler> */}

          {/* <MDBCollapse navbar open={openBasic}>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
            <MDBNavbarItem>
              <MDBNavbarLink active aria-current='page' href='#'>
                Home
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='#'>Link</MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                  Dropdown
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem link>Action</MDBDropdownItem>
                  <MDBDropdownItem link>Another action</MDBDropdownItem>
                  <MDBDropdownItem link>Something else here</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink disabled href='#' tabIndex={-1} aria-disabled='true'>
                Disabled
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>

          <form className='d-flex input-group w-auto'>
            <input type='search' className='form-control' placeholder='Type query' aria-label='Search' />
            <MDBBtn color='primary'>Search</MDBBtn>
          </form>
        </MDBCollapse> */}
          <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
            {/* <MDBNavbarItem>
              <MDBNavbarLink active aria-current="page" href="#">
                Search
              </MDBNavbarLink>
            </MDBNavbarItem> */}
            <MDBNavbarItem>
              <MDBNavbarLink
                active
                aria-current="page"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate("/admin");
                }}
              >
                Dashboard
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBContainer>
      </MDBContainer>
    </MDBNavbar>
  );
}
