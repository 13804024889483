import React from "react";
import { MDBCard } from "mdb-react-ui-kit";
import {
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
  CardText,
  Label,
  CardHeader,
} from "reactstrap";
const VoterCard = ({ voter }) => {
  const renderName = () => {
    if (voter.LASTNAME_V1 && voter.LASTNAME_V1 != "") {
      let values = voter.LASTNAME_V1.split(" ");
      let newValues = "";
      if (voter.FM_NAME_V1.indexOf(voter.RLN_FM_NM_V1) == -1) {
        newValues = newValues + voter.RLN_FM_NM_V1+" ";
      }
      for (let i = 0; i < values.length; i++) {
        if (voter.FM_NAME_V1.indexOf(values[i]) == -1) {
          newValues = newValues + values[i];
        }
      }

      return (
        voter.FM_NAME_V1 +
        " " +
        newValues
      );
    } else {
      return (
        voter.FM_NAME_V1
      );
    }
  };
  return (
    <div>
      <MDBCard
        shadow="0"
        border="light"
        background="white"
        id={"voter-card"}
        className="mt-0 w-100 voter-card"
      >
        <CardHeader className="w-100 text-center mb-0">
          <p className="mb-0"><strong>सांगली विधानसभा मतदारसंघ</strong></p>
        </CardHeader>
        <div class="bd mb-2 mt-0"></div>
        <CardBody>
          <ListGroup>
            <ListGroupItem>
              <div className="label-value">
                <Label>विधानसभा क्र.:</Label>
                <CardText>{voter.AC_NO}</CardText>
              </div>
              <div className="label-value">
                <Label>यादिभाग:</Label>
                <CardText>{voter.PART_NO}</CardText>
              </div>
            </ListGroupItem>

            <ListGroupItem>
              <div className="label-value">
                <Label>नाव:</Label>
                <CardText>
                  {renderName()}
                </CardText>
              </div>
              <div className="label-value">
                <Label>अ.क्रं.:</Label>
                <CardText>{voter.SLNOINPART}</CardText>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <div className="label-value">
                <Label>वोटिंग कार्ड:</Label>
                <CardText>{voter.EPIC_NO}</CardText>
              </div>
              <div className="label-value">
                <Label>वय:</Label>
                <CardText>{voter.AGE}</CardText>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <div className="label-value">
                <Label>पत्ता:</Label>
                <CardText>{voter.adr2}</CardText>
              </div>
            </ListGroupItem>
            {/* <ListGroupItem>
                            <div className='label-value'>
                                <Label>बूथ:</Label>
                                <CardText>{voter.SECTION_NO}</CardText>
                            </div>
                        </ListGroupItem> */}
            <ListGroupItem>
              <div className="label-value">
                <Label>मतदानाचे ठिकाण:</Label>
                <CardText>{voter.POLLING_STATION_ADR2}</CardText>
              </div>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
        <div class="bd mb-2"></div>
      </MDBCard>
    </div>
  );
};

export default VoterCard;
