export const customFetch = (url, options, successHandler, errorHandler) => {
  return fetch(url, options)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.json();
      } else {
        if (res.status === 401) {
          errorHandler("401");
          localStorage.clear();
          // alert("here")
          window.location.href = "/login";
          //   throw new Error("Invalid Username/Password. Please try again!");
        }
        if (res.status === 500) {
          throw new Error("Server Error");
        }
      }
    })
    .then((data) => {
      successHandler(data);
      return data;
    })
    .catch((error) => {
      errorHandler(error.message);
      return [];
    });
};


export const customPublicFetch = (url, options, successHandler, errorHandler) => {
  return fetch(url, options)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.json();
      } else {
        if (res.status === 401) {
          errorHandler("401");
          // localStorage.clear();
          // // alert("here")
          // window.location.href = "/login";
          //   throw new Error("Invalid Username/Password. Please try again!");
        }
        if (res.status === 500) {
          throw new Error("Server Error");
        }
      }
    })
    .then((data) => {
      successHandler(data);
      return data;
    })
    .catch((error) => {
      errorHandler(error.message);
      return null;
    });
};