import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
  MDBIcon,
  MDBTypography,
} from "mdb-react-ui-kit";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import logo from "../assets/logo123.png";
import { useNavigate } from "react-router-dom";
export default function ResultCard({ voter, onClick }) {
  const navigate = useNavigate();

  const renderName = () => {
    if (voter.LASTNAME_V1 && voter.LASTNAME_V1 != "") {
      let values = voter.LASTNAME_V1.split(" ");
      let newValues = "";
      if (voter.FM_NAME_V1.indexOf(voter.RLN_FM_NM_V1) == -1) {
        newValues = newValues + voter.RLN_FM_NM_V1+" ";
      }
      for (let i = 0; i < values.length; i++) {
        if (voter.FM_NAME_V1.indexOf(values[i]) == -1) {
          newValues = newValues + values[i];
        }
      }

      return (
        voter.FM_NAME_V1 +
        " " +
        newValues +
        " (" +
        voter.AGE +
        " " +
        voter.GENDER +
        ")" +
        " - " +
        voter.EPIC_NO
      );
    } else {
      return (
        voter.FM_NAME_V1 +
        " (" +
        voter.AGE +
        " " +
        voter.GENDER +
        ")" +
        " - " +
        voter.EPIC_NO
      );
    }
  };
  return (
    <MDBCard
      shadow="0"
      border="light"
      background="white"
      className="mt-3 w-100 voter-card-summary"
      onClick={onClick}
    >
      {/* {voter.soundexEnglish.map((key) => (
        <MDBTypography className="voter-details-secondary">{key}</MDBTypography>
      ))} */}
      <MDBCardBody>
        <Row>
          <Col>
            <MDBTypography variant="h6" tag="strong">
              {renderName()}
            </MDBTypography>
          </Col>
        </Row>
        <Row>
          <Col>
            <MDBTypography className="voter-details-secondary">
              {"(यादिभाग-" +
                voter.SECTION_NO +
                ")-(अ.क्रं.:" +
                voter.SLNOINPART +
                ")"}
            </MDBTypography>
            <MDBTypography className="voter-details-secondary">
              {"(" + voter.adr2 + ")(" + voter.POLLING_STATION_ADR2 + ")"}
            </MDBTypography>
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </MDBCardBody>
    </MDBCard>
  );
}
