import { customFetch } from "./base-fetch";
import { api_base_url } from "./baseurl";

export const signin = (username, password, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/auth/login`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    },
    successHandler,
    errorHandler
  );
};
