import { customFetch } from "./base-fetch";
import { api_base_url } from "./baseurl";

export const searchUser = (search, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/voters/search`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify(search),
    },
    successHandler,
    errorHandler
  );
};

export const advancedSearchUser = (search, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/voters/advance-search`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify(search),
    },
    successHandler,
    errorHandler
  );
};

export const advancedSearchUserForRelation = (search, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/voters/advance-search-for-relation`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify(search),
    },
    successHandler,
    errorHandler
  );
};


export const fetchConstituency = (
  constituency,
  successHandler,
  errorHandler
) => {
  return customFetch(
    `${api_base_url}/voters/constituency/${constituency}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

