import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { addUser, editUser } from "../../../services/admin-service";

const UserDetailsModal = ({
  user,
  toggle,
  isOpen,
  onSave,
  isEdit,
  onClosed,
  rolesList,
  fetchUsersList,
  orgId,
}) => {
  const [userData, setUserData] = useState(
    user
      ? { ...user }
      : {
          name: "",
          email: "",
          phoneNumber: "",
          roleId: "",
          isAdmin: false,
          isActive: true,
          username: "",
          password: "",
          organizationId: orgId,
        }
  );

  const setEmptyUserData = () => {
    setUserData({
      name: "",
      email: "",
      phoneNumber: "",
      roleId: "",
      isAdmin: false,
      isActive: true,
      username: "",
      password: "",
      organizationId: orgId,
    });
  };

  useEffect(() => {
    if (isEdit && user) {
      setUserData(user);
    } else {
      setEmptyUserData();
    }
  }, [isOpen, isEdit, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveUser();
  };

  const saveUser = async () => {
    if (userData._id && userData._id != "") {
      let result = await editUser(
        userData,
        () => {},
        () => {}
      );
    } else {
      let result = await addUser(
        userData,
        () => {},
        () => {}
      );
    }
    fetchUsersList();
    setEmptyUserData();
    toggle();
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop={"static"}
        onClosed={onClosed}
      >
        <ModalHeader toggle={toggle}>
          {isEdit ? "Edit User" : "Add User"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="firstName">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={userData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              {/* <Col md={6}>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={userData.lastName}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col> */}
            </Row>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={userData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="phoneNumber">Phone</Label>
              <Input
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                value={userData.phoneNumber}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup switch className="mt-1 mb-1">
              <Label check>Is Admin</Label>
              <Input
                type="switch"
                checked={userData.isAdmin}
                onClick={() => {
                  setUserData({ ...userData, isAdmin: !userData.isAdmin });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="roleId">Role</Label>
              <Input
                type="select"
                name="roleId"
                id="roleId"
                value={userData.roleId}
                onChange={handleChange}
                required
              >
                <option disabled={userData.roleId != ""}>Select</option>
                {rolesList.map((role) => (
                  <option value={role._id}>{role.role}</option>
                ))}
              </Input>
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    value={userData.username}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    value={userData.password}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Button color="primary" type="submit">
                {isEdit ? "Save" : "Add User"}
              </Button>{" "}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UserDetailsModal;
