import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import AddRelativeForm from "./relation/add-relation-form";
function AddRelativeModal(props) {
  const toggle = () => props.setShowModal(!props.modal);
  const [action, setAction] = useState("");
  useEffect(() => {
    if (!props.modal) {
      setAction("resetForm");
    }
  }, [props.modal]);
  const [surveyRelationStatus, setSurveyRelationStatus] = useState({
    isSubmitting: false,
    isDisabled: false,
    allowSubmitAction: true,
  });
  return (
    <div>
      {/* <Button color="danger" onClick={toggle}>
                Click Me
            </Button> */}
      <Modal isOpen={props.modal} toggle={toggle}>
        <ModalHeader>Add Family Member</ModalHeader>
        <ModalBody>
          <AddRelativeForm
            {...props}
            action={action}
            setAction={setAction}
            toggle={toggle}
            setVoterId={() => {}}
            surveyRelationStatus={surveyRelationStatus}
            setSurveyRelationStatus={setSurveyRelationStatus}
          />
        </ModalBody>
        <ModalFooter>
          {surveyRelationStatus.isSubmitting ? (
            <Button
              color="primary"
              // disabled={surveyRelationStatus.isDisabled}
              // onClick={addRelationData}
            >
              <Spinner size={"sm"} />
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={surveyRelationStatus.isDisabled}
              onClick={() => {
                setAction("addRelation");
              }}
            >
              Add
            </Button>
          )}{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddRelativeModal;
