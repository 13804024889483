import React from "react";

import { MDBNavbarBrand } from "mdb-react-ui-kit";
import { Navbar } from "reactstrap";

const PublicTopbar = () => {
  return (
    <>
      <Navbar className="navbar p-2 ps-0 bg-white" expand="lg" container="lg">
        <MDBNavbarBrand href="/" style={{ fontWeight: 500 }}>
          <img
            width="30"
            style={{ objectFit: "contain", marginRight: 15 }}
            src={require("../assets/images/logo/Antridge_Icon_PNG.png")}
          />
          Proxima Voters
        </MDBNavbarBrand>
      </Navbar>
    </>
  );
};

export default PublicTopbar;
