import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

export const defaultData = [
  ["Voters", "Voters By Gender"],
  // ["Male", 47],
  // ["Female", 45],
  // ["Other", 8],
  //   ["Watch TV", 2],
  //   ["Sleep", 7],
];

export const options = {
  title: "Voters by Gender",
  is3D: true,
};

const VoterGenderPie = ({ votersReport }) => {
  const [data, setData] = useState(defaultData);
  useEffect(() => {
    if (votersReport && votersReport._id) {
      let data = [...defaultData];
      data.push(["Male", votersReport.votersByGender.male]);
      data.push(["Female", votersReport.votersByGender.female]);
      data.push(["Transgender", votersReport.votersByGender.transgender]);
      data.push(["Other", votersReport.votersByGender.other]);

      setData(data)
    }
  }, [votersReport]);
  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"400px"}
    />
  );
};

export default VoterGenderPie;
