import React from "react";
import { Button, Col, Row } from "reactstrap";
import VoterCard from "./voter-card";

const VoterDetailCard = (props) => {
  const renderName = () => {
    if (props.voter.LASTNAME_V1 && props.voter.LASTNAME_V1 != "") {
      let values = props.voter.LASTNAME_V1.split(" ");
      let newValues = "";
      for (let i = 0; i < values.length; i++) {
        if (props.voter.FM_NAME_V1.indexOf(values[i]) == -1) {
          newValues = newValues + values[i];
        }
      }

      return (
        props.voter.FM_NAME_V1 +
        " " +
        newValues
      );
    } else {
      return (
        props.voter.FM_NAME_V1
      );
    }
  };
  const handleSendToWhatsApp = () => {
    const sectionContent = document.getElementById("voter-card").innerText;
    const message = encodeURIComponent(
      `२८२-सांगली विधानसभा निवडणूक २०२४
    ------------------------------------------------
    *बुथ क्र. : ${props.voter.SECTION_NO}*
    *अनु क्र. : ${props.voter.SLNOINPART}*
    नाव : ${renderName()}
    *मतदान केंद्र : ${props.voter.POLLING_STATION_ADR2}*
    ------------------------------------------------
    *पृथ्वीराज पाटील*
    अध्यक्ष, सांगली शहर काँग्रेस`
    );
    // console.log(message);
    // const encodedImageUrl = encodeURIComponent(
    //   `https://hindi.news18.com/news/jharkhand/ranchi-weighing-150-grams-after-wearing-there-will-be-no-need-of-ac-this-matka-saree-is-ready-in-10-days-8326723.html?utm_medium=social&utm_source=whatsapp&utm_campaign=local18-editorial`
    // );
    const whatsappURL = `https://wa.me/?text=${message}`;

    window.open(whatsappURL, "_blank");
  };
  const handleSendToSMS = () => {
    const sectionContent = document.getElementById("voter-card").innerText;
    // const message = encodeURIComponent(sectionContent);
    const message = encodeURIComponent(
      `२८२-सांगली विधानसभा निवडणूक २०२४
    ------------------------------------------------
    *बुथ क्र. : ${props.voter.SECTION_NO}*
    *अनु क्र. : ${props.voter.SLNOINPART}*
    नाव : ${renderName()}
    *मतदान केंद्र : ${props.voter.POLLING_STATION_ADR2}*
    ------------------------------------------------
    *पृथ्वीराज पाटील*
    अध्यक्ष, सांगली शहर काँग्रेस `
    );
    // /मतदान : -- दि. -- - ---- रोजी. स. ७ ते सायं. ६
    const smsURL = `sms:?&body=${message}`;
    window.open(smsURL, "_self");
  };
  return (
    <>
      <Row>
        <Col>
          <VoterCard voter={props.voter} />
        </Col>
      </Row>
      <Row className={"mt-1"}>
        <Col className="button-right-group">
          <Button
            outline
            color="secondary"
            className="mr-3"
            onClick={handleSendToSMS}
          >
            Message
          </Button>
          <Button color="success" onClick={handleSendToWhatsApp}>
            Whatsapp
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default VoterDetailCard;
