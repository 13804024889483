import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

const UserSelect = ({ users, setSelectedUser, selectedUser }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(searchValue)
    );
    setFilteredUsers(filtered);
    // setSelectedUser(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    // alert(selectedUser._id);
    if (selectedUser && selectedUser._id) {
      //    alert(selectedUser.name);
    //   console.log("dreop:",dropdownOpen)
      
      setSearchTerm(selectedUser.name);
    }
  }, [selectedUser]);

//   useEffect(() => {
//     console.log(dropdownOpen);
//   }, [dropdownOpen]);
  return (
    <FormGroup>
      <Label for="userSelect">Select User</Label>
      <Input
        type="search"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search user"
        onFocus={() => setDropdownOpen(true)}
        // onBlur={() => setDropdownOpen(false)}
      />
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => {}}
        split
        style={{
          setPosition: "relative",
          display: "block",
        }}
      >
        <DropdownToggle
          className="dropdown-toggle-hiddens"
          style={{
            backgroundColor: "transparent",
            border: "none",
            padding: 0,
            setPosition: "absolute",
            left: "95%",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
        <DropdownMenu className="dropdown-menu-user">
          {filteredUsers.map((user) => (
            <DropdownItem
              key={user._id}
              value={user._id}
              onClick={() => {
                setDropdownOpen(false);
                setSelectedUser(user);
              }}
            >
              {user.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </FormGroup>
  );
};
export default UserSelect;
