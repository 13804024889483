export const relationsMap = [
  { relation: "Father", marathi: "वडील" },
  { relation: "Mother", marathi: "आई" },
  { relation: "Son", marathi: "मुलगा" },
  { relation: "Daughter", marathi: "मुलगी" },
  { relation: "Brother", marathi: "भाऊ" },
  { relation: "Sister", marathi: "बहीण" },
  { relation: "Grandfather", marathi: "आजोबा" },
  { relation: "Grandmother", marathi: "आजी" },
  { relation: "Uncle", marathi: "काका" },
  { relation: "Aunt", marathi: "काकू" },
  { relation: "Nephew", marathi: "पुतण्या" },
  { relation: "Niece", marathi: "पुतणी" },
  { relation: "Cousin (Male)", marathi: "चुलत भाऊ" },
  { relation: "Cousin (Female)", marathi: "चुलत बहीण" },
  { relation: "Husband", marathi: "पती" },
  { relation: "Wife", marathi: "पत्नी" },
  { relation: "Father-in-law", marathi: "सासरे" },
  { relation: "Mother-in-law", marathi: "सासू" },
  { relation: "Brother-in-law (Husband's Brother)", marathi: "दीर" },
  { relation: "Brother-in-law (Wife's Brother)", marathi: "मेव्हणा" },
  { relation: "Sister-in-law (Husband's Sister)", marathi: "नणंद" },
  { relation: "Sister-in-law (Wife's Sister)", marathi: "साळी" },
  { relation: "Son-in-law", marathi: "जावई" },
  { relation: "Daughter-in-law", marathi: "सून" },
  { relation: "Friend (Male)", marathi: "मित्र" },
  { relation: "Friend (Female)", marathi: "मैत्रीण" },
  { relation: "Other", marathi: "अन्य" },
];

export const relationsSurvererMap = [
  { relation: "Friends", marathi: "मित्र/मैत्रीण" },
  { relation: "Relatives", marathi: "पाहुणे" },
  { relation: "Closed Relatives", marathi: "नात्यातले" },
  { relation: "Collegue", marathi: "सहकारी" },
  { relation: "Other", marathi: "अन्य" },
];
