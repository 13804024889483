import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AdminApp from "./admin/admin";
import Dashboard from "./views/App";
import ReportsPage from "./views/Reports";
import VoterSurveyPage from "./views/VoterSurvey";
import { AuthLogin } from "./views/auth-login";
import { useEffect, useState } from "react";
import SearchApp from "./views/Search";
function App() {
  const [user, setUser] = useState({});

  const refreshUser = () => {
    // alert("here");
    try {
      let userData = JSON.parse(localStorage.getItem("voter-user"));
      if (userData && userData._id && userData._id != "") {
        setUser(userData);
      } else {
        setUser(null);
      }
    } catch (err) {
      setUser(null);
    }
  };
  useEffect(refreshUser, []);
  return (
    <BrowserRouter>
      {user && user._id ? (
        <Routes>
          <Route
            path="/login"
            Component={() => <AuthLogin refreshUser={refreshUser} />}
          />
          <Route path="/*" Component={() => <Dashboard user={user}/>}/>
          <Route path="/voter/:id" Component={VoterSurveyPage} />
          <Route path="/reports" Component={ReportsPage} />

          <Route path="/admin/*" Component={() => <AdminApp user={user} />} />
        </Routes>
      ) : user ? (
        <Routes>
          <Route
            path="/login"
            Component={() => <AuthLogin refreshUser={refreshUser} />}
          />
          {/* <Route path="/*" Component={() => <Navigate to="/login" />} /> */}
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/search"
            Component={() => <SearchApp/>}
          />
           <Route
            path="/login"
            Component={() => <AuthLogin refreshUser={refreshUser} />}
          />
          <Route path="/*" Component={() => <Navigate to="/search" />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
