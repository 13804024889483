import React from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBBadge,
} from "mdb-react-ui-kit";
import usePagination from "../../hooks/usePagination";
import Pagination from "./pagination";
import noPhoto from "../../../assets/images/no-photo.svg";
import SearchAndSort from "./SearchAndSort";

const UsersTable = ({
  data,
  rolesList,
  onEditClick,
  onDeleteClick,
  sortOptions,
}) => {
  const pageSize = 10;
  const {
    currentPage,
    totalPages,
    currentData,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    searchTerm,
    setSearchTerm,
    sortKey,
    setSortKey,
    sortOrder,
    setSortOrder,
  } = usePagination(data, pageSize);

  return (
    <>
      <SearchAndSort
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        sortKey={sortKey}
        setSortKey={setSortKey}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortOptions={sortOptions}
      />
      <div className="table-responsive">
        <MDBTable align="middle">
          <MDBTableHead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Role</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {currentData.map((user) => (
              <tr key={user.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <img
                      src={noPhoto}
                      alt=""
                      style={{ width: "45px", height: "45px" }}
                      className="rounded-circle"
                    />
                    <div className="ms-3">
                      <p className="fw-bold mb-0">{user.name}</p>
                      <p className="text-muted mb-0">{user.email}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="fw-normal mb-0">
                    {rolesList.filter((role) => user.roleId == role._id).length
                      ? rolesList.filter((role) => user.roleId == role._id)[0]
                          .role
                      : "Not Found"}
                  </p>
                  {/* <p className="text-muted mb-0">IT department</p> */}
                </td>
                <td>
                  {user.isActive ? (
                    <MDBBadge color="success" pill>
                      Active
                    </MDBBadge>
                  ) : (
                    <MDBBadge color="danger" pill>
                      In Active
                    </MDBBadge>
                  )}
                </td>
                {/* <td>Senior</td> */}
                <td>
                  <MDBBtn
                    color="link"
                    rounded
                    size="sm"
                    onClick={() => onEditClick(user)}
                  >
                    Edit
                  </MDBBtn>
                  <MDBBtn
                    color="link"
                    rounded
                    size="sm"
                    onClick={() => onDeleteClick(user)}
                  >
                    Delete
                  </MDBBtn>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

export default UsersTable;
