import React, { useState } from "react";

import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import ConfirmationModal from "../../components/modals/confirmation-modal";
import MyProfileModal from "../../components/modals/my-profile-modal";

const Topbar = ({ toggleSidebar, user }) => {
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [logoutconfirmationModal, setLogoutConfirmationModal] = useState(false);
  const [myProfileModal, setMyProfileModal] = useState(false);
  const navigate = useNavigate();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const logoutConfirmationModalToggle = () =>
    setLogoutConfirmationModal(!logoutconfirmationModal);
  const myProfileModalToggle = () => setMyProfileModal(!myProfileModal);

  const handleLogout = () => {
    // Handle Logout here
    localStorage.clear();
    navigate("/");
    setLogoutConfirmationModal(false);
  };

  const handleMyProfileChanges = () => {
    // Handle changes in my profile here
    setMyProfileModal(false);
  };

  const handleMyProfileModalClosed = () => {
    // if required, use this to do something when MyProfileModal gets closed by
    // either by clicking on cancel button or toggled using state
  };

  return (
    <>
      <Navbar
        color="light"
        light
        className="navbar p-2 ps-0 bg-white"
        expand="md"
      >
        <MDBBtn color="link" rippleColor="dark" onClick={toggleSidebar}>
          <MDBIcon fas color="dark" icon="bars" size="lg" className="mr-4" />
        </MDBBtn>
        {/* <NavbarToggler onClick={toggleTopbar} /> */}
        {/* <Collapse isOpen={topbarIsOpen} navbar> */}
        <div>
          <Nav className="ms-auto">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
              <DropdownToggle nav caret>
                {user ? user.name : "NA"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setMyProfileModal(true)}>
                  Account Settings
                </DropdownItem>
                <DropdownItem onClick={() => setLogoutConfirmationModal(true)}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* </Collapse> */}
      </Navbar>
      <ConfirmationModal
        isOpen={logoutconfirmationModal}
        toggle={logoutConfirmationModalToggle}
        title={"Logout"}
        description={"Are you sure you want to logout?"}
        positiveButtonText={"Logout"}
        onConfirm={handleLogout}
      />
      <MyProfileModal
        toggle={myProfileModalToggle}
        isOpen={myProfileModal}
        onSave={handleMyProfileChanges}
        onClosed={handleMyProfileModalClosed}
        user={user}
      />
    </>
  );
};

export default Topbar;
