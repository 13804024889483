import React, { useEffect, useState } from "react";

import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import VoterReligionPie from "./voter-religion-pie";
import VoterReligionGenderWiseChart from "./voter-religion-gender-bar";
import VoterGenderPie from "../voters/voter-gender-pie";
import VoterAgeWiseChart from "../voters/voter-age-bar";
import VoterSurveySummary from "./voter-survey-summary";
import { fetchVotersSurveySummaryReport } from "../../../../services/report-service";

const VoterSurveySummaryReportsSection = ({ survey }) => {
  const [report, setReport] = useState({});
  const fetchData = async (surveyId) => {
    let data = await fetchVotersSurveySummaryReport(
      surveyId,
      () => {},
      () => {}
    );
    if (data) {
      setReport(data);
    }
  };

  useEffect(() => {
    if (survey && survey._id) {
      fetchData(survey._id);
    }
  }, [survey]);
  return (
    <>
      <MDBRow className="justify-content-center">
        <MDBCol md="6" className="mb-3">
          <MDBCard>
            <MDBCardBody>
            <VoterGenderPie votersReport={report}/>
              {/* <VoterSurveySummary votersReport={report}/> */}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <VoterAgeWiseChart votersReport={report}/>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow className="justify-content-center">
        <MDBCol md="6" className="mb-3">
          {/* <MDBCard>
            <MDBCardBody>
              <VoterGenderPie votersReport={report}/>
            </MDBCardBody>
          </MDBCard> */}
        </MDBCol>
        <MDBCol md="6">
          {/* <MDBCard>
            <MDBCardBody>
            <VoterAgeWiseChart />
            </MDBCardBody>
          </MDBCard> */}
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default VoterSurveySummaryReportsSection;
