// src/components/common/SearchAndSort.js
import React, { useEffect } from "react";
import { Col, Input, Row } from "reactstrap";
import { MDBInputGroup, MDBInput } from "mdb-react-ui-kit";

const SearchAndSort = ({
  searchTerm,
  setSearchTerm,
  sortKey,
  setSortKey,
  sortOrder,
  setSortOrder,
  sortOptions,
}) => {
  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleSortKeyChange = (event) => {
    setSortKey(event.target.value);
  };

  useEffect(() => {
    // On first launch, set first key as default key for sort
    setSortKey(sortOptions[0]?.value);
  }, []);

  return (
    <Row className="justify-content-between mt-4 mb-4">
      <Col lg={6} md={4} className="align-self-center">
        <div className="d-flex">
          <MDBInputGroup
            className="mb-3 mb-md-0 flex-grow-1 flex-md-grow-0"
            style={{ flexBasis: "300px" }}
          >
            <MDBInput
              label="Search"
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </MDBInputGroup>
        </div>
      </Col>
      <Col lg={6} md={8}>
        <div className="d-flex align-items-center justify-content-md-end justify-content-start">
          <span className="me-2">Sort by:</span>
          <div className="me-2 flex-grow-1 flex-md-grow-0">
            <Input
              id="sortBy"
              name="sortBy"
              type="select"
              value={sortKey}
              onChange={handleSortKeyChange}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
          </div>
          <div className="flex-grow-1 flex-md-grow-0">
            <Input
              id="sortOrder"
              name="sortOrder"
              type="select"
              value={sortOrder}
              onChange={handleSortOrderChange}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Input>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SearchAndSort;
