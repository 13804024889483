import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { Link, NavLink as RouterNavLink } from "react-router-dom";
import { MDBIcon } from "mdb-react-ui-kit";

const SubMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  const { icon, title, items } = props;

  return (
    <div>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed })}
      >
        <NavLink className="dropdown-toggle p-4 pt-2 pb-2">
          <MDBIcon fas icon={icon} className="mr-4" />
          <span className="ms-3">{title}</span>
        </NavLink>
      </NavItem>
      <Collapse
        isOpen={!collapsed}
        navbar
        className={classNames("items-menu", { "mb-1": !collapsed })}
      >
        {items.map((item, index) => (
          <NavItem key={index}>
            <NavLink
              tag={RouterNavLink}
              to={item.target}
              className="p-4 pt-2 pb-2"
            >
              {item.title}
            </NavLink>
          </NavItem>
        ))}
      </Collapse>
    </div>
  );
};

export default SubMenu;
