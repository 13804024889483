import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./content.css";

import Home from "../../views/home";
import Roles from "../../views/roles";
import Users from "../../views/users.js";
import MySurveys from "../../views/my-surveys.js";
import Surveys from "../../views/surveys.js";
import Reports from "../../views/reports.js";

const Content = ({ sidebarIsOpen, toggleSidebar, user }) => (
  <div className="content">
    {/* <Hello/> */}
    {user.isSuperAdmin ? (
      <Routes>
        {/* <Route  path="/admin/*" component={() => "Hello"} /> */}
        <Route exact path="home" element={<Home />} />
        <Route exact path="users" element={<Users />} />
        <Route
          exact
          path="my-surveys"
          Component={() => <MySurveys user={user} />}
        />
        <Route exact path="surveys" Component={() => <Surveys user={user} />} />
        <Route exact path="reports" Component={() => <Reports user={user} />} />
        <Route exact path="roles" element={<Roles />} />
        <Route
          exact
          path="survey-settings"
          component={() => "Survey Settings"}
        />
        <Route path="*" element={<Navigate to="/admin/home" />} />
      </Routes>
    ) : user.isAdmin ? (
      <Routes>
        {/* <Route  path="/admin/*" component={() => "Hello"} /> */}
        <Route exact path="home" element={<Home />} />
        <Route exact path="users" element={<Users />} />
        <Route exact path="surveys" Component={() => <Surveys user={user} />} />
        <Route
          exact
          path="my-surveys"
          Component={() => <MySurveys user={user} />}
        />
         <Route exact path="reports" Component={() => <Reports user={user} />} />
        <Route path="*" element={<Navigate to="/admin/home" />} />
      </Routes>
    ) : (
      <Routes>
        {/* <Route  path="/admin/*" component={() => "Hello"} /> */}
        <Route exact path="home" element={<Home />} />

        <Route
          exact
          path="my-surveys"
          Component={() => <MySurveys user={user} />}
        />
        <Route path="*" element={<Navigate to="/admin/home" />} />
      </Routes>
    )}
  </div>
);

export default Content;
