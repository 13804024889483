import React from "react";
import { NavItem, NavLink, Nav, NavbarText } from "reactstrap";
import classNames from "classnames";
import { Link, NavLink as RouterNavLink } from "react-router-dom";
import "./sidebar.css";
import SubMenu from "./SubMenu";
import { MDBIcon } from "mdb-react-ui-kit";

const SideBar = ({ isOpen, toggle, user }) => (
  <aside className={classNames("sidebar", { "is-open": isOpen })}>
    <div className="sidebar-header p-4 pt-4 pb-4 d-flex">
      <span color="info" onClick={toggle} style={{ color: "#fff" }}>
        &times;
      </span>
      <img
        width="30"
        style={{ objectFit: "contain", marginRight: 15 }}
        src={require("../../../assets/images/logo/Antridge_Icon_PNG.png")}
      />
      <div className="logo-text">Proxima Voters</div>
    </div>
    <div className="side-menu pt-3">
      <Nav vertical className="list-unstyled pb-3">
        <NavItem>
          <NavLink
            tag={RouterNavLink}
            to={"/admin/home"}
            className="p-4 pt-2 pb-2"
          >
            <MDBIcon fas icon="home" className="mr-4" />
            <span className="ms-3">Home</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={RouterNavLink}
            to={"/admin/my-surveys"}
            className="p-4 pt-2 pb-2"
          >
            <MDBIcon fas icon="clipboard-list" className="mr-4" />
            <span className="ms-3">My Surveys</span>
          </NavLink>
        </NavItem>
        <NavbarText className="section-title ms-4 mt-2">Search</NavbarText>
        <NavItem>
          <NavLink tag={RouterNavLink} to={"/"} className="p-4 pt-2 pb-2">
            <MDBIcon fas icon="search" className="mr-4" />
            <span className="ms-3">Go to Search</span>
            <MDBIcon fas icon="external-link-alt" className="mr-4 ms-2" />
          </NavLink>
        </NavItem>
        {user.isAdmin || user.isSuperAdmin ? (
          <>
            <NavbarText className="section-title ms-4 mt-2">
              Admin Tools
            </NavbarText>
            <NavItem>
              <NavLink
                tag={RouterNavLink}
                to={"/admin/users"}
                className="p-4 pt-2 pb-2"
              >
                <MDBIcon fas icon="user" className="mr-4" />
                <span className="ms-3">Users</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={RouterNavLink}
                to={"/admin/roles"}
                className="p-4 pt-2 pb-2"
              >
                <MDBIcon fas icon="user-cog" className="mr-4" />
                <span className="ms-3">Roles</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={RouterNavLink}
                to={"/admin/surveys"}
                className="p-4 pt-2 pb-2"
              >
                <MDBIcon fas icon="clipboard-list" className="mr-4" />
                <span className="ms-3">Surveys</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={RouterNavLink}
                to={"/admin/reports"}
                className="p-4 pt-2 pb-2"
              >
                <MDBIcon fas icon="poll" className="mr-4" />
                <span className="ms-3">Reports</span>
              </NavLink>
            </NavItem>
          </>
        ) : (
          ""
        )}
        {user.isSuperAdmin ? (
          <>
            <NavbarText className="section-title ms-4 mt-2">
              Master Settings
            </NavbarText>
            <SubMenu title="Super Admin" icon="lock" items={submenus[0]} />
          </>
        ) : (
          ""
        )}
      </Nav>
    </div>
  </aside>
);

const submenus = [
  [
    {
      title: "Survey Settings",
      target: "survey-settings",
    },
  ],
];

export default SideBar;
