import React, { useEffect, useState } from "react";

import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import VoterReligionPie from "./voter-religion-pie";
import VoterReligionGenderWiseChart from "./voter-religion-gender-bar";
import VoterGenderPie from "../voters/voter-gender-pie";
import VoterAgeWiseChart from "../voters/voter-age-bar";
import VoterSurveySummary from "./voter-survey-summary";
import { religions } from "../../../../components/religions";
import { professions } from "../../../../components/profession-constant";
import VoterQuestion from "./voter-question";
import { relationsSurvererMap } from "../../../../components/relations-constant";
import { fetchVotersSurveyQuestionWiseReport } from "../../../../services/report-service";

const VoterSurveyReportsSection = ({ survey }) => {
  const questions = [
    {
      id: 1,
      text: "संपर्क झाला",
      field: "isContacted",
      isMultiple: false,
      isBoolean: true,
    },
    {
      id: 2,
      text: "मयत",
      field: "isDead",
      isMultiple: false,
      isBoolean: true,
    },
    {
      id: 3,
      text: "कुटुंब प्रमुख",
      field: "isHead",
      isMultiple: false,
      isBoolean: true,
    },
    {
      id: 4,
      text: "लाभार्थी",
      field: "isBeneficiary",
      isMultiple: false,
      isBoolean: true,
    },
    {
      id: 5,
      text: "Relation With Surveyer",
      field: "relationToSurveyer",
      isMultiple: true,
      isBoolean: false,
      values: [...relationsSurvererMap.map((relation) => relation.marathi)],
    },
    {
      id: 6,
      text: "मतदान व्यवस्थेत कल",
      field: "voterPreference",
      isMultiple: true,
      isBoolean: false,
      values: ["A", "B", "C"],
    },
    {
      id: 7,
      text: "धर्म",
      field: "religion",
      isMultiple: true,
      isBoolean: false,
      values: [...religions.map((religion) => religion.religion)],
    },
    {
      id: 8,
      text: "जात",
      field: "caste",
      isMultiple: true,
      isBoolean: false,
      isDependent: true,
      parent: "7",
    },
    {
      id: 9,
      text: "स्थलांतरित",
      field: "isRelocated",
      isMultiple: false,
      isBoolean: true,
    },
    {
      id: 10,
      text: "Relocated To",
      field: "relocatedTo",
      isMultiple: true,
      isBoolean: false,
      values: ["InDistrict", "OutDistrict"],
      displayLabels: ["जिल्ह्यामध्ये", "जिल्ह्याबाहेर"],
    },
    {
      id: 11,
      text: "व्यवसाय",
      field: "occupation",
      isMultiple: true,
      isBoolean: false,
      values: [
        "माहित नाही",
        ...professions.map((profession) => profession.marathi),
      ],
    },
  ];

  const [surveyReport, setSurveyReport] = useState({
    totalVoters: 0,
    isContacted: 0,
    isDead: 0,
    isHead: 0,
    isBeneficiary: 0,
    isRelocated: 0,
    phoneNumber: 0,
    whatsappNumber: 0,
    voterPreference: {},
    relationToSurveyer: {},
    religion: {},
    caste: {},
    relocatedTo: {},
    occupation: {},
  });

  const fetchCountData = async (survyeId) => {
    let result = await fetchVotersSurveyQuestionWiseReport(
      survyeId,
      () => {},
      () => {}
    );
    if (result && result._id) {
      setSurveyReport(result);
    }
  };
  useEffect(() => {
    if (survey && survey._id) {
      fetchCountData(survey._id);
    }
  }, [survey]);
  return (
    <>
      <MDBRow className="justify-content-center">
        <MDBCol md="12" className="mb-3">
          {questions.map((question) => (
            <VoterQuestion
              question={question}
              surveyReport={surveyReport[question.field]}
              totalVoters={surveyReport.totalVoters}
            />
          ))}
        </MDBCol>
        {/* <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <VoterAgeWiseChart />
            </MDBCardBody>
          </MDBCard>
        </MDBCol> */}
      </MDBRow>
      {/* <MDBRow className="justify-content-center">
        <MDBCol md="6" className="mb-3">
          <MDBCard>
            <MDBCardBody>
              <VoterGenderPie />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <VoterAgeWiseChart />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow className="justify-content-center">
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <VoterReligionPie />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <VoterReligionGenderWiseChart />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow> */}
    </>
  );
};

export default VoterSurveyReportsSection;
