import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

export const defaultData = [["Voter Age", "Males", "Females", "Others"]];

export const options = {
  title: "Voters By Age Group",
  chartArea: { width: "50%" },
  is3D: true,
  hAxis: {
    title: "Total Voters",
    minValue: 0,
  },
  vAxis: {
    title: "Age Group",
  },
};

const VoterAgeWiseChart = ({ votersReport }) => {
  const [data, setData] = useState(defaultData);
  useEffect(() => {
    if (votersReport && votersReport._id) {
      let data = [...defaultData];
      let ageGroupData = { ...votersReport.votersByAgeGroup };
      delete ageGroupData._id;
      Object.keys(ageGroupData).map((key) => {
        data.push([
          `${key} Years`,
          ageGroupData[key].male,
          ageGroupData[key].female,
          ageGroupData[key].transgender + ageGroupData[key].other,
        ]);
      });
      // data.push(["Male", votersReport.votersByGender.male]);
      // data.push(["Female", votersReport.votersByGender.female]);
      // data.push(["Transgender", votersReport.votersByGender.transgender]);
      // data.push(["Other", votersReport.votersByGender.other]);

      setData(data);
    }
  }, [votersReport]);
  return (
    <Chart
      chartType="BarChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
};

export default VoterAgeWiseChart;
