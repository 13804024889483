import { MDBIcon } from "mdb-react-ui-kit";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import {
  getVoterSurveyBySurveyId,
  saveVoterSurvey,
  updateVoterSurvey,
} from "../../services/voter-survey-service";
import { professions } from "../profession-constant";
import { relationsSurvererMap } from "../relations-constant";
import { religions } from "../religions";
const SurveyForm = (props) => {
  const [isCopied, setCopied] = useState(false);
  const [isOtherOccupation, setIsOtherOccupation] = useState(false);
  const [voterSurvey, setVoterSurvey] = useState({
    religion: "",
    caste: "",
    isDead: false,
    isFamilyHead: false,
    isContacted: false,
    voterPreference: "",
    phoneNumber: "",
    whatsappNumber: "",
    notes: "",
    occupationType: "",
    occupation: "",
    isRelocated: false,
    relocatedTo: "",
    relocatedPlace: "",
    relations: [],
    primarySurveyer: {
      id: "",
      name: "",
      relationToSurveyer: "अन्य",
      action: "Create",
      version: 1,
    },

    surveyer: [],
    created_at: "",
    updated_at: "",
  });
  const [user, setUser] = useState({ constituencies: [], booths: [] });
  useEffect(() => {
    if (props.surveyId && props.voterId && props.modal) {
      fetchSurveyData(props.voterId, props.surveyId, false);
    }
  }, [props.surveyId && props.voterId && props.modal]);

  const fetchSurveyData = async (voterId, surveyId, isParent) => {
    let voterSurveyData = await getVoterSurveyBySurveyId(
      voterId,
      surveyId,
      () => {},
      () => {}
    );

    if (voterSurveyData && voterSurveyData._id) {
      if (isParent) {
        delete voterSurveyData._id;
        voterSurveyData.voterId = props.voterId;
        voterSurveyData.isFamilyHead = false;
        if (voterSurvey._id) {
          voterSurveyData._id = voterSurvey._id;
        }
        console.log(voterSurveyData);
        setVoterSurvey(voterSurveyData);
      } else {
        setVoterSurvey(voterSurveyData);
      }
    } else {
      setVoterSurvey({
        ...voterSurvey,
        voterId: voterId,
        surveyId: surveyId,
      });
    }
  };

  const pattern = new RegExp(/^\d{1,10}$/);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("voter-user"));
    setUser(userData);
  }, []);
  const submitSurvey = async () => {
    if (voterSurvey && voterSurvey._id) {
      let surveyData = await updateVoterSurvey(
        voterSurvey,
        () => {},
        () => {}
      );
      if (surveyData && surveyData._id) {
        setVoterSurvey(surveyData);
        props.setSurveySubmitStatus({
          ...props.surveySubmitStatus,
          isSubmitting: false,
          allowSubmitAction: true,
        });
        props.toggle();
      }
    } else {
      voterSurvey.primarySurveyer.id = user?._id;
      let surveyData = await saveVoterSurvey(
        voterSurvey,
        () => {},
        () => {}
      );
      if (surveyData && surveyData._id) {
        setVoterSurvey(surveyData);
        props.fetchSurveyData();
        props.setSurveySubmitStatus({
          ...props.surveySubmitStatus,
          isSubmitting: false,
          allowSubmitAction: true,
        });
        props.toggle();
      }
    }
  };

  useEffect(() => {
    if (props.action == "submitSurvey") {
      props.setSurveySubmitStatus({
        ...props.surveySubmitStatus,
        isSubmitting: true,
        allowSubmitAction: false,
      });
      submitSurvey();
    }
    if (props.action == "resetForm") {
      resetSurvey();
    }
  }, [props.action]);

  const resetSurvey = () => {
    setVoterSurvey({
      religion: "",
      caste: "",
      isDead: false,
      isFamilyHead: false,
      isBeneficiary: false,
      isContacted: false,
      voterPreference: "",
      phoneNumber: "",
      whatsappNumber: "",
      notes: "",
      occupation: "",
      isRelocated: false,
      relocatedTo: "",
      relocatedPlace: "",
      relations: [],
      primarySurveyer: {
        id: user?._id,
        name: "",
        relationToSurveyer: "अन्य",
        action: "Create",
        version: 1,
      },

      surveyer: [],
      created_at: "",
      updated_at: "",
    });
  };

  const copySurvey = () => {
    if (isCopied) {
      resetSurvey();
      setCopied(!isCopied);
    } else {
      fetchSurveyData(props.headId, props.surveyId, true);
      setCopied(!isCopied);
    }
  };

  return (
    <Fragment>
      <Row className={"mt-1"}>
        <Col>
          <FormGroup switch>
            <Label check>संपर्क झाला</Label>
            <Input
              type="switch"
              checked={voterSurvey.isContacted}
              onClick={() => {
                setVoterSurvey({
                  ...voterSurvey,
                  isContacted: !voterSurvey.isContacted,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup switch>
            <Label check>मयत</Label>
            <Input
              type="switch"
              checked={voterSurvey.isDead}
              onClick={() => {
                setVoterSurvey({
                  ...voterSurvey,
                  isDead: !voterSurvey.isDead,
                });
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      {voterSurvey.isDead ? (
        ""
      ) : (
        <>
          {props.isShowCopy && props.headId ? (
            <Row className={"mt-4"}>
              <Col>
                <FormGroup switch>
                  <Label check>कुटुंब प्रमुख प्रमाणे</Label>
                  <Input
                    type="switch"
                    checked={isCopied}
                    onClick={() => {
                      copySurvey();
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row className={"mt-4"}>
            <Col>
              <FormGroup switch>
                <Label check>लाभार्थी</Label>
                <Input
                  type="switch"
                  checked={voterSurvey.isBeneficiary}
                  onClick={() => {
                    setVoterSurvey({
                      ...voterSurvey,
                      isBeneficiary: !voterSurvey.isBeneficiary,
                    });
                  }}
                />
              </FormGroup>
            </Col>
           
            <Col>
              <FormGroup>
                <Label for="exampleSelect">Relation With Surveyer</Label>
                <InputGroup>
                  <InputGroupText style={{ height: "auto" }}>
                    <MDBIcon fas icon="person" />
                  </InputGroupText>
                  <Input
                    placeholder="Relation"
                    value={voterSurvey.primarySurveyer.relationToSurveyer}
                    onChange={(e) => {
                      setVoterSurvey({
                        ...voterSurvey,
                        primarySurveyer: {
                          ...voterSurvey.primarySurveyer,
                          relationToSurveyer: e.target.value,
                        },
                      });
                    }}
                    name="select"
                    type="select"
                  >
                    <option
                      disabled={
                        voterSurvey.primarySurveyer.relationToSurveyer != ""
                      }
                    >
                      Select
                    </option>
                    {relationsSurvererMap.map((relation) => (
                      <option>{relation.marathi}</option>
                    ))}
                  </Input>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col>
              <Label check>मतदान व्यवस्थेत कल</Label>
              <div className="inline-check">
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={voterSurvey.voterPreference === "A"}
                    onChange={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        voterPreference: "A",
                      })
                    }
                  />

                  <Label
                    check
                    onClick={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        voterPreference: "A",
                      })
                    }
                  >
                    A
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={voterSurvey.voterPreference === "B"}
                    onChange={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        voterPreference: "B",
                      })
                    }
                  />

                  <Label
                    check
                    onClick={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        voterPreference: "B",
                      })
                    }
                  >
                    B
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={voterSurvey.voterPreference === "C"}
                    onChange={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        voterPreference: "C",
                      })
                    }
                  />

                  <Label
                    check
                    onClick={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        voterPreference: "C",
                      })
                    }
                  >
                    C
                  </Label>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col md={6} sm={6}>
              <FormGroup>
                <Label for="exampleSelect">धर्म</Label>
                <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
                  value={voterSurvey.religion}
                  onChange={(e) =>
                    setVoterSurvey({
                      ...voterSurvey,
                      religion: e.target.value,
                      caste: "",
                    })
                  }
                >
                  <option value="" disabled={voterSurvey.religion != ""}>
                    Select
                  </option>
                  {religions.map((religion) => (
                    <option value={religion.religion}>
                      {religion.religion}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={6} sm={6}>
              <FormGroup>
                <Label for="exampleSelect">जात</Label>

                <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
                  value={voterSurvey.caste}
                  onChange={(e) =>
                    setVoterSurvey({
                      ...voterSurvey,
                      caste: e.target.value,
                    })
                  }
                >
                  <option value="" disabled={voterSurvey.caste != ""}>
                    Select
                  </option>
                  {religions
                    .filter(
                      (religion) => religion.religion == voterSurvey.religion
                    )
                    .map((religion) =>
                      religion.caste.map((caste) => (
                        <option value={caste}>{caste}</option>
                      ))
                    )}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col>
              <Label check>स्थलांतरित</Label>
              <div className="inline-check">
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={!voterSurvey.isRelocated}
                    onChange={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        isRelocated: false,
                        relocatedTo: "",
                      })
                    }
                  />

                  <Label
                    check
                    onClick={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        isRelocated: false,
                        relocatedTo: "",
                      })
                    }
                  >
                    नाही
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={voterSurvey.relocatedTo == "InDistrict"}
                    onChange={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        relocatedTo: "InDistrict",
                        isRelocated: true,
                      })
                    }
                  />

                  <Label
                    check
                    onClick={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        relocatedTo: "InDistrict",
                        isRelocated: true,
                      })
                    }
                  >
                    जिल्ह्यामध्ये
                  </Label>
                </FormGroup>

                <FormGroup check>
                  <Input
                    type="radio"
                    checked={voterSurvey.relocatedTo == "OutDistrict"}
                    onChange={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        relocatedTo: "OutDistrict",
                        isRelocated: true,
                      })
                    }
                  />
                  <Label
                    check
                    onClick={() =>
                      setVoterSurvey({
                        ...voterSurvey,
                        relocatedTo: "OutDistrict",
                        isRelocated: true,
                      })
                    }
                  >
                    जिल्ह्याबाहेर
                  </Label>
                </FormGroup>
              </div>
              {voterSurvey.isRelocated ? (
                <FormGroup className="mt-2">
                  <Label for="exampleSelect">
                    {voterSurvey.relocatedTo === "OutDistrict"
                      ? "जिल्हा"
                      : "ठिकाण"}
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter place"
                    value={voterSurvey.relocatedPlace}
                    onChange={(e) =>
                      setVoterSurvey({
                        ...voterSurvey,
                        relocatedPlace: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col>
              <Label for="exampleSelect">मोबाईल नंबर</Label>
              <InputGroup>
                <InputGroupText className="input-group-with-icon">
                  <MDBIcon fas icon="phone" />
                </InputGroupText>
                <Input
                  invalid={
                    (voterSurvey.phoneNumber.length != 10 &&
                      voterSurvey.phoneNumber.length != 0) ||
                    (voterSurvey.phoneNumber.length != 0 &&
                      !pattern.test(voterSurvey.phoneNumber))
                  }
                  className="input-group-with-icon"
                  placeholder="Enter mobile number"
                  value={voterSurvey.phoneNumber}
                  onChange={(e) => {
                    if (
                      e.target.value.length == 0 ||
                      (e.target.value.length <= 10 &&
                        pattern.test(e.target.value))
                    ) {
                      setVoterSurvey({
                        ...voterSurvey,
                        phoneNumber: e.target.value,
                      });
                    }
                  }}
                />
              </InputGroup>
              <FormFeedback>Invalid Phone number</FormFeedback>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col>
              <Label for="exampleSelect">मोबाईल नंबर (WhatsApp)</Label>
              <InputGroup>
                <InputGroupText className="input-group-with-icon">
                  <MDBIcon fab icon="whatsapp" />
                </InputGroupText>
                <Input
                  invalid={
                    (voterSurvey.whatsappNumber.length != 10 &&
                      voterSurvey.whatsappNumber.length != 0) ||
                    (voterSurvey.whatsappNumber.length != 0 &&
                      !pattern.test(voterSurvey.whatsappNumber))
                  }
                  className="input-group-with-icon"
                  placeholder="Enter WhatsApp number"
                  value={voterSurvey.whatsappNumber}
                  onChange={(e) => {
                    if (
                      e.target.value.length == 0 ||
                      (e.target.value.length <= 10 &&
                        pattern.test(e.target.value))
                    ) {
                      setVoterSurvey({
                        ...voterSurvey,
                        whatsappNumber: e.target.value,
                      });
                    }
                  }}
                />
              </InputGroup>
              <FormFeedback>Invalid Whatsapp number</FormFeedback>
            </Col>
          </Row>
          <Row className={"mt-4"}>
            <Col>
              <FormGroup>
                <Label for="exampleSelect">व्यवसाय</Label>
                <InputGroup>
                  <InputGroupText style={{ height: "auto" }}>
                    <MDBIcon fas icon="person" />
                  </InputGroupText>
                  <Input
                    className="input-group-with-icon"
                    placeholder="Enter occupation"
                    value={voterSurvey.occupationType}
                    onChange={(e) => {
                      if (e.target.value == "अन्य") {
                        setIsOtherOccupation(true);
                        setVoterSurvey({
                          ...voterSurvey,
                          occupation: "",
                          occupationType: e.target.value,
                        });
                      } else {
                        setVoterSurvey({
                          ...voterSurvey,
                          occupation: e.target.value,
                          occupationType: e.target.value,
                        });
                        setIsOtherOccupation(false);
                      }
                    }}
                    name="select"
                    type="select"
                  >
                    <option disabled={voterSurvey.occupationType != ""}>
                      Select
                    </option>
                    {professions.map((profession) => (
                      <option>{profession.marathi}</option>
                    ))}
                  </Input>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          {isOtherOccupation ? (
            <Row>
              <Col>
                <Label for="exampleSelect">व्यवसाय</Label>
                <InputGroup>
                  <InputGroupText className="input-group-with-icon">
                    <MDBIcon fas icon="briefcase" />
                  </InputGroupText>
                  <Input
                    className="input-group-with-icon"
                    placeholder="Enter occupation"
                    value={voterSurvey.occupation}
                    onChange={(e) =>
                      setVoterSurvey({
                        ...voterSurvey,
                        occupation: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      )}
      <Row className={"mt-4"}>
        <Col>
          <FormGroup>
            <Label for="exampleSelect">टिप्पणी</Label>
            <Input
              type="textarea"
              placeholder="Enter notes"
              value={voterSurvey.notes}
              onChange={(e) =>
                setVoterSurvey({ ...voterSurvey, notes: e.target.value })
              }
            />
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SurveyForm;
