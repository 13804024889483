import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBIcon,
} from "mdb-react-ui-kit";
import React from "react";

const StatCard = ({ icon, name, value, upValue }) => {
  return (
    <MDBCard>
      <MDBCardBody>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <div className="p-3 bg-primary rounded-4 shadow-2-strong">
              <MDBIcon icon={icon} size="lg" className="text-white fa-fw" />
            </div>
          </div>
          <div className="flex-grow-1 ms-4">
            <p className="text-muted mb-1">{name}</p>
            <h2 className="mb-0">
              {value}
              {upValue ? (
                <span className="text-success" style={{ fontSize: "0.875rem" }}>
                  <MDBIcon icon="arrow-up" className="ms-1" size="sm" />
                  <span> 5.4%</span>
                </span>
              ) : (
                ""
              )}
            </h2>
          </div>
        </div>
      </MDBCardBody>
      {/* <MDBCardFooter background="light" className="border-0 p-2">
        <MDBBtn color="none" className="ms-3" tag="a" href="#">
          View more <MDBIcon icon="arrow-right" className="ms-2" />
        </MDBBtn>
      </MDBCardFooter> */}
    </MDBCard>
  );
};

export default StatCard;
