import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import Navbar from "../layout/navbar";
import VoterSurvey from "../components/voter-survey";
import ReportsDashboard from "../components/reports-dashboard";

function ReportsPage() {
  return (
    <>
      <Navbar isShowBackButton={true} />
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12" lg="10" offsetMd="1">
            {/* Content Here */}
            <ReportsDashboard />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default ReportsPage;
