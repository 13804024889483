import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import React from "react";
import { Chart } from "react-google-charts";
import { BooleanBarChart } from "./boolean-question-chart";
import MultiplePieChart from "./multiple-pie";

export const data = [
  ["Voters", "Surveyed Voters"],
  ["Surveyed Voters", 50],
  ["Not Surveyed Voters", 50],
];

export const options = {
  title: "Survey Summary Report",
  pieHole: 0.4,
  is3D: false,
};

const VoterQuestion = ({ question, surveyReport, totalVoters }) => {
  return (
    <MDBCard>
      <MDBCardBody>
        <h6>{question.text}</h6>
        {question.isBoolean ? (
          <BooleanBarChart
            question={question}
            surveyReport={surveyReport}
            totalVoters={totalVoters}
          />
        ) : null}
        {question.isMultiple ? (
          <MultiplePieChart
            question={question}
            surveyReport={surveyReport}
            totalVoters={totalVoters}
          />
        ) : null}
      </MDBCardBody>
    </MDBCard>
  );
};

export default VoterQuestion;
