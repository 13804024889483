import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
  MDBIcon,
} from "mdb-react-ui-kit";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";

export default function ReportsDashboard() {
  return (
    <MDBCard
      shadow="0"
      border="light"
      background="white"
      className="mt-3 w-100"
    >
      <MDBCardBody>
        <MDBCardTitle>Reports</MDBCardTitle>

        <Row className="mt-3">
          <Col>
            <Card>
              <CardHeader>मतदान व्यवस्थेत कल</CardHeader>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <Card
                      style={{
                        width: "18rem",
                      }}
                    >
                      <CardBody>
                        <CardTitle tag="h5">Special Title Treatment</CardTitle>
                        <CardText>
                          With supporting text below as a natural lead-in to
                          additional content.
                        </CardText>
                        <Button>Go somewhere</Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <CardBody>
                        <h6>Title</h6>
                        <p>value</p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <CardBody>
                        <h6>Title</h6>
                        <p>value</p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Card>
              <CardHeader>Survey Report</CardHeader>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <Card>
                      <CardBody>
                        <h6>Title</h6>
                        <p>value</p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <CardBody>
                        <h6>Title</h6>
                        <p>value</p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <CardBody>
                        <h6>Title</h6>
                        <p>value</p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </MDBCardBody>
    </MDBCard>
  );
}
