import { MDBIcon } from "mdb-react-ui-kit";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { relationsMap } from "../relations-constant";
import ResultCard from "../result-card";
import { advancedSearchUser, advancedSearchUserForRelation, searchUser } from "../../services/search-service";
import { addRelation } from "../../services/voter-survey-service";
import { useNavigate } from "react-router-dom";
import AdvancedSearchCard from "../advanced-search-card";
const AddRelativeAdvancedForm = (props) => {
  const [advanceSearch, setAdvanceSearch] = useState({
    // constituency: 282,
    firstName: "",
    lastName: "",
    address: "",
    voterId: "",
    page: 0,
    relationIds: [],
  });
  const navigate = useNavigate();
  const [voter, setVoter] = useState({});
  const [relation, setRelation] = useState({
    voterId: "",
    relationName: "",
    headId: "",
    childOf: "",
    partnerOf: "",
    gender: "",
    isWidow: false,
    organizationId: "",
  });

  const [voters, setVoters] = useState([]);
  useEffect(() => {
    console.log(advanceSearch);
    if (
      advanceSearch.firstName != "" ||
      advanceSearch.lastName != "" ||
      advanceSearch.address != "" ||
      advanceSearch.voterId != ""
    ) {
      const fetchData = async () => {
        let data = await advancedSearchUserForRelation(
          advanceSearch,
          () => {},
          (message) => {
            if (message == "401") {
              localStorage.clear();
              navigate("/login");
            }
          }
        );
        setVoter({});
        setVoters(data.hits);
      };
      const debounceFetchData = setTimeout(fetchData, 500); // Debounce API call by 300ms

      return () => clearTimeout(debounceFetchData);
    } else {
      console.log("in error");
      setVoters([]);
    }
  }, [advanceSearch]);

  useEffect(() => {
    if (
      relation &&
      relation.voterId &&
      relation.headId &&
      relation.relationName != ""
    ) {
      props.setSurveyRelationStatus({
        ...props.surveyRelationStatus,
        isShowSurvey: true,
        isDisabled: false,
        isSubmitting: false,
        allowSubmitAction: true,
      });
      props.setVoterId(relation.voterId);
    } else {
      props.setSurveyRelationStatus({
        ...props.surveyRelationStatus,
        isShowSurvey: false,
        isDisabled: true,
        isSubmitting: false,
        allowSubmitAction: false,
      });
      props.setVoterId("");
    }
  }, [relation]);

  useEffect(() => {
    if (props.voter && props.voter._id) {
      setAdvanceSearch({
        ...advanceSearch,
        firstName: props.voter.FM_NAME_EN,
        lastName: props.voter.LASTNAME_EN ? props.voter.LASTNAME_EN : "",
        address: props.voter.adr1,
        relationIds:
          props.relationIds && props.relationIds.length
            ? props.relationIds
            : [],
      });
      setRelation({
        ...relation,
        headId: props.voter._id,
        gender: props.voter.GENDER,
        organizationId: props.orgId,
      });
    }
  }, [props.voter, props.relationIds]);

  const addRelationData = async () => {
    if (
      relation &&
      relation.voterId &&
      relation.headId &&
      relation.relationName != ""
    ) {
      let relationData = await addRelation(
        relation,
        () => {},
        () => {}
      );
      if (relationData && relationData.voterId) {
        //   setVoterSurvey(surveyData);
        props.fetchRelationsData();
        props.setSurveyRelationStatus({
          ...props.surveyRelationStatus,
          isSubmitting: false,
          allowSubmitAction: true,
        });
        props.toggle();
      } else {
        props.fetchRelationsData();
        alert("error while adding relation");
        props.setSurveyRelationStatus({
          ...props.surveyRelationStatus,
          isSubmitting: false,
          allowSubmitAction: true,
        });
        props.toggle();
      }
    } else {
      alert("Please fill the details");
    }
  };

  useEffect(() => {
    // console.log(props.action)
    if (props.action == "addRelation") {
      props.setSurveyRelationStatus({
        ...props.surveyRelationStatus,
        isSubmitting: true,
        allowSubmitAction: false,
      });
      addRelationData();
    }
    if (props.action == "resetForm") {
      setRelation({
        voterId: "",
        relationName: "",
        headId: "",
        childOf: "",
        partnerOf: "",
        gender: "",
        isWidow: false,
      });

      setVoters([]);
      setVoter({});
      setAdvanceSearch({
        ...advanceSearch,
        firstName: "",
        lastName: "",
        voterId: "",
        address: "",
        relationIds: [],
      });
      props.setAction("");
    }
  }, [props.action]);

  return (
    <Fragment>
      <AdvancedSearchCard
        setSearch={setAdvanceSearch}
        setPagination={() => {}}
        search={advanceSearch}
      />
      {voter && voter._id && voter._id != "" ? (
        <>
          <Row className="mb-3">
            <Col>
              <ResultCard voter={voter} onClick={() => {}} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <FormGroup>
                <Label for="exampleSelect">Select Relation</Label>
                <InputGroup>
                  <InputGroupText style={{ height: "auto" }}>
                    <MDBIcon fas icon="person" />
                  </InputGroupText>
                  <Input
                    placeholder="Relation"
                    value={relation.relationName}
                    onChange={(e) => {
                      setRelation({
                        ...relation,
                        relationName: e.target.value,
                        organizationId: props.orgId,
                      });
                    }}
                    name="select"
                    type="select"
                  >
                    <option disabled={relation.relationName != ""}>
                      Select
                    </option>
                    {relationsMap.map((relation) => (
                      <option>{relation.marathi}</option>
                    ))}
                  </Input>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          {/* <Row className="mb-3">
                <Col>
                  <InputGroup>
                    <InputGroupText>
                      <MDBIcon fas icon="phone" />
                    </InputGroupText>
                    <Input
                      placeholder="phone Number"
                      value={relation.contactNo}
                      onChange={(e) => {
                        setRelation({ ...relation, contactNo: e.target.value });
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row> */}
        </>
      ) : (
        <div className="result-section">
          {voters.map((voterData, index) => (
            <ResultCard
              voter={voterData}
              key={index}
              onClick={() => {
                setVoter({ _id: voterData.voterId, ...voterData });
                setRelation({
                  ...relation,
                  voterId: voterData.voterId,
                  headId: props.voter?._id,
                  gender: props.voter?.GENDER,
                  organizationId: props.orgId,
                });
              }}
            />
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default AddRelativeAdvancedForm;
