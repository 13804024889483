import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { addRole, editRole, fetchPermissions } from "../../../services/admin-service";

const RoleDetailsModal = ({
  role,
  toggle,
  isOpen,
  onSave,
  isEdit,
  onClosed,
  orgId,
  fetchRolesData
}) => {
  const [permissions, setPermissions] = useState([]);
  const [roleData, setRoleData] = useState(
    role
      ? role
      : {
          role: "",
          permissions: [],
          orgId,
        }
  );

  const setEmptyRoleData = () => {
    setRoleData({
      role: "",
      permissions: [],
      orgId,
    });
  };

  useEffect(() => {
    if (isEdit && role) {
      setRoleData(role);
    } else {
      setEmptyRoleData();
    }
  }, [isOpen, isEdit, role, orgId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleData({
      ...roleData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveRole();

    // toggle(); // Close the modal after submission
  };

  const fetchPermisionsData = async () => {
    let result = await fetchPermissions(
      "",
      () => {},
      () => {}
    );
    if (result && result.length) {
      setPermissions(result);
    }
  };

  const saveRole = async () => {
    if (roleData._id && roleData._id != "") {
      let result = await editRole(
        roleData,
        () => {},
        () => {}
      );
    } else {
      let result = await addRole(
        roleData,
        () => {},
        () => {}
      );
    }
    fetchRolesData()
    setEmptyRoleData();
    toggle();
  };

  useEffect(() => {
    fetchPermisionsData();
  }, []);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop={"static"}
        onClosed={onClosed}
      >
        <ModalHeader toggle={toggle}>
          {isEdit ? "Edit Role" : "Add Role"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Role Name</Label>
              <Input
                type="text"
                name="role"
                id="name"
                value={roleData.role}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <div>
              <Label>Permissions</Label>
            </div>

            {permissions.map((permission) => (
              <FormGroup check inline key={permission._id}>
                <Input
                  id={("check-", permission._id)}
                  name={("check-", permission._id)}
                  type="checkbox"
                />
                <Label check for={("check-", permission._id)}>
                  {permission.name}
                </Label>
              </FormGroup>
            ))}

            <ModalFooter className="mt-3">
              <Button color="primary" type="submit">
                {isEdit ? "Save" : "Add Role"}
              </Button>{" "}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RoleDetailsModal;
