import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmationModal = ({
  isOpen,
  toggle,
  title,
  description,
  positiveButtonText,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={"static"}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{description}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onConfirm}>
          {positiveButtonText}
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
