import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import HeaderImage from "../components/voter-header-image";
import SearchSection from "../components/search-section";
import Navbar from "../layout/navbar";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VoterSurvey from "../components/voter-survey";

import { useParams } from "react-router-dom";
import { getActiveSurvey, getVoter } from "../services/voter-survey-service";
import VoterDetailCard from "../components/voter-detail-card";
import Topbar from "../layout/topbar";
function VoterSurveyPage() {
  const navigate = useNavigate();
  const [voter, setVoter] = useState({});
  const [survey, setSurvey] = useState(null);

  const [user, setUser] = useState({});
  useEffect(() => {
    if (
      !localStorage.getItem("voter-user-access-token") ||
      localStorage.getItem("voter-user-access-token") == ""
    ) {
      navigate("/login");
    } else {
      let userData = JSON.parse(localStorage.getItem("voter-user"));
      setUser(userData);
    }
  }, []);

  const { id } = useParams();

  const fetchData = useCallback(async () => {
    console.log("DHIRAJ CALL");
    let voter = await getVoter(
      id,
      () => {},
      (message) => {
        if (message == "401") {
          localStorage.clear();
          navigate("/login");
        }
      }
    );
    if (voter) {
      setVoter(voter);
    }

    let activeSurvey = await getActiveSurvey(
      user.organizationId,
      () => {},
      () => {}
    );
    if (activeSurvey && activeSurvey._id) {
      setSurvey(activeSurvey);
      // await getActiveSurvey(()=>{},()=>{})
    }
  }, [id, user.organizationId]);
  useEffect(() => {
    if (id && user.organizationId) {
      console.log("DHIRAJ" + id);
      // const fetchData = async () => {};
      fetchData();
    }
  }, [fetchData]);

  return (
    <div className="main-content">
      {/* <Navbar isShowBackButton={true} /> */}
      <Topbar user={user} isShowBackButton={true}/>
      <div className="content">
        <MDBContainer>
          <MDBRow>
            <MDBCol sm="12" lg="10" offsetMd="1">
              <HeaderImage isVoter={true} />
              {survey && survey._id && survey._id != "" ? (
                <VoterSurvey
                  voterId={id}
                  voter={voter}
                  survey={survey}
                  orgId={user?.organizationId}
                  user={user}
                />
              ) : (
                <VoterDetailCard voter={voter} />
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
}

export default VoterSurveyPage;
