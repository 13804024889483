import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCol,
  MDBBadge,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
  MDBCardFooter,
} from "mdb-react-ui-kit";
import StatCard from "./stat-card";

function StatCardContainer({ tileReport }) {
  return (
    <MDBContainer fluid>
      <MDBRow className="justify-content-center mb-3">
        <MDBCol md="12">
          <section>
            {/* <h5 className="mb-4">Last month</h5> */}
            <MDBRow>
              <MDBCol md="4" className="mb-md-0">
                <StatCard
                  name="Total Voters"
                  value={tileReport.totalVoters}
                  icon="hand-point-up"
                />
              </MDBCol>

              <MDBCol md="4" className="mb-md-0">
                <StatCard
                  name="Total Surveyers"
                  value={tileReport.totalSurveyors}
                  icon="eye"
                />
              </MDBCol>

              <MDBCol md="4" className="mb-md-0">
                <StatCard
                  name="Total Surveyed"
                  value={tileReport.totalSurveyedVoters}
                  icon="chart-pie"
                />
              </MDBCol>
            </MDBRow>
          </section>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default StatCardContainer;
