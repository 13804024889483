import { customFetch } from "./base-fetch";
import { api_base_url } from "./baseurl";

export const fetchPermissions = (search, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/roles/permissions/all`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const fetchRoles = (orgId, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/roles/${orgId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const addRole = (role, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/roles`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify(role),
    },
    successHandler,
    errorHandler
  );
};

export const editRole = (role, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/roles/${role._id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify(role),
    },
    successHandler,
    errorHandler
  );
};

export const deleteRole = (role, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/roles/${role._id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const fetchUsers = (orgId, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/users/all/${orgId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};

export const addUser = (user, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/users`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify(user),
    },
    successHandler,
    errorHandler
  );
};

export const editUser = (user, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/users/${user._id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
      body: JSON.stringify(user),
    },
    successHandler,
    errorHandler
  );
};

export const deleteUser = (userId, successHandler, errorHandler) => {
  return customFetch(
    `${api_base_url}/users/${userId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "voter-user-access-token"
        )}`,
      },
    },
    successHandler,
    errorHandler
  );
};
