import { customPublicFetch } from "./base-fetch";
import { api_base_url } from "./baseurl";

export const searchPubUser = (search, successHandler, errorHandler) => {
  return customPublicFetch(
    `${api_base_url}/voters/pub/search`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(search),
    },
    successHandler,
    errorHandler
  );
};

export const advancedPubSearchUser = (search, successHandler, errorHandler) => {
  return customPublicFetch(
    `${api_base_url}/voters/pub/advance-search`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(search),
    },
    successHandler,
    errorHandler
  );
};

export const fetchPubConstituency = (
  constituency,
  successHandler,
  errorHandler
) => {
  return customPublicFetch(
    `${api_base_url}/voters/constituency/pub/${constituency}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
    successHandler,
    errorHandler
  );
};
