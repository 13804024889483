import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

// export const data = [
//   ["Voters", "Voters By Religion"],
//   ["Male", 47],
//   ["Female", 45],
//   ["Other", 8],
//   //   ["Watch TV", 2],
//   //   ["Sleep", 7],
// ];

// export const options = {
//   title: "Voters by Religion",
//   is3D: true,
// };

const MultiplePieChart = ({ question, surveyReport,totalVoters }) => {
  const data = [["प्रश्न", "Total"]];
  const doptions = {
    title: question.text,
    is3D: true,
  };
  const [chartData, setData] = useState([...data]);
  const [options, setOptions] = useState({ ...doptions });

  useEffect(() => {
    if(surveyReport){
      console.log(surveyReport,question)
      let newData = [...data];
      Object.keys(surveyReport).map((key) => {
        if(key=="" ){
          // key="माहित नाही"
          if(question.field == "relocatedTo"){
            // newData.push(["माहित नाही", Number(surveyReport[key])]);
          }else{
            newData.push(["माहित नाही", Number(surveyReport[key])]);
          }
         
        }else  if(key=="InDistrict"){
          // key="माहित नाही"
          newData.push(["जिल्ह्यामध्ये", Number(surveyReport[key])]);
        }else  if(key=="OutDistrict"){
          // key="माहित नाही"
          newData.push(["जिल्ह्याबाहेर", Number(surveyReport[key])]);
        }
        else{
          newData.push([key, Number(surveyReport[key])]);
        }
        
      });
  
      setData(newData);
    }
   
  }, [question, surveyReport, totalVoters]);
  return (
    <Chart
      chartType="PieChart"
      data={chartData}
      options={options}
      width={"100%"}
      height={"400px"}
    />
  );
};

export default MultiplePieChart;
