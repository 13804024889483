import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import SurveyForm from "./relation/survey-form";
function SurveyModal(props) {
  const [action, setAction] = useState("");
  const toggle = () => props.setShowModal(!props.modal);
  const [surveySubmitStatus, setSurveySubmitStatus] = useState({
    isSubmitting: false,
    allowSubmitAction: true,
  });

  useEffect(() => {
    if (!props.modal) {
      setAction("resetForm");
      setSurveySubmitStatus({
        isSubmitting: false,
        allowSubmitAction: true,
      });
    }
  }, [props.modal]);
  return (
    <div>
      <Modal isOpen={props.modal} toggle={toggle}>
        <ModalHeader>Survey</ModalHeader>
        <ModalBody>
          <SurveyForm
            headId={props.headId}
            action={action}
            {...props}
            toggle={toggle}
            surveySubmitStatus={surveySubmitStatus}
            setSurveySubmitStatus={setSurveySubmitStatus}
          />
        </ModalBody>
        <ModalFooter>
          <Row className="mt-3">
            <Col>
              {surveySubmitStatus.isSubmitting ? (
                <Button color="primary" className="mr-3" onClick={() => {}}>
                  <Spinner size={"sm"} />
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="mr-3"
                  onClick={() => setAction("submitSurvey")}
                >
                  Save
                </Button>
              )}
              <Button
                color="secondary"
                onClick={() => {
                  toggle();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SurveyModal;
