import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["City", "2010 Population", "2000 Population"],
  ["New York City, NY", 8175000, 8008000],
  ["Los Angeles, CA", 3792000, 3694000],
  ["Chicago, IL", 2695000, 2896000],
  ["Houston, TX", 2099000, 1953000],
  ["Philadelphia, PA", 1526000, 1517000],
];

export const options = {
  title: "Population of Largest U.S. Cities",
  chartArea: { width: "50%" },
  isStacked: true,
  hAxis: {
    title: "Total Population",
    minValue: 0,
  },
  vAxis: {
    title: "City",
  },
};

export function BooleanBarChart({question,surveyReport,totalVoters}) {
   const data = [
    ["प्रश्न", "हो", "नाही"],
    
  ];
  const doptions = {
    title: question.text,
    chartArea: { width: "50%" },
    isStacked: true,
    hAxis: {
      title: "Total Surveyed Voters",
      minValue: 0,
    },
    vAxis: {
      title: "",
    },
    colors: ['#3366cc', '#f5f5f5'], 
  };
  const [chartData,setData] = useState([...data])
  const [options,setOptions] = useState({...doptions})
   

  useEffect(()=>{
    let newData = [...data]
    newData.push([question.text,Number(surveyReport),Number(totalVoters)-Number(surveyReport)])
    setData(newData)
},[question,surveyReport,totalVoters])
  return (
    <Chart
      chartType="BarChart"
      width="100%"
      height="400px"
      data={chartData}
      options={options}
    />
  );
}
