import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
  MDBIcon,
  MDBTypography,
} from "mdb-react-ui-kit";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  Button,
} from "reactstrap";
import logo from "../assets/logo123.png";
import { useNavigate } from "react-router-dom";
import ResultCard from "./result-card";
import {
  getVoterSurveyBySurveyId,
  removeRelation,
} from "../services/voter-survey-service";
import SurveyModal from "./survey-modal";
export default function FamilyMemberCard({
  voter,
  relation,
  relationId,
  surveyId,
  relationHead,
  voterSurveyData,
  fetchRelationsData,
}) {
  const navigate = useNavigate();
  const [isSurveyModalShow, setShowSurveyModal] = useState(false);
  const [voterSurvey, setVoterSurvey] = useState({
    religion: "",
    caste: "",
    isDead: false,
    isFamilyHead: false,
    isContacted: false,
    voterPreference: "",
    phoneNumber: "",
    whatsappNumber: "",
    notes: "",
    occupation: "",
    occupationType: "",
    isRelocated: false,
    relocatedTo: "",
    relocatedPlace: "",
    relations: [],
  });
  const fetchSurveyData = async () => {
    let voterSurveyData = await getVoterSurveyBySurveyId(
      voter._id,
      surveyId,
      () => {},
      () => {}
    );

    if (voterSurveyData && voterSurveyData._id) {
      setVoterSurvey(voterSurveyData);
    } else {
      setVoterSurvey({
        religion: "",
        caste: "",
        isDead: false,
        isFamilyHead: false,
        isContacted: false,
        voterPreference: "",
        phoneNumber: "",
        whatsappNumber: "",
        notes: "",
        occupation: "",
        isRelocated: false,
        relocatedTo: "",
        relocatedPlace: "",
        relations: [],
        voterId: voter.voterId,
        surveyId: surveyId,
      });
    }
  };

  useEffect(() => {
    if (surveyId && voter?._id) {
      fetchSurveyData();
    }
  }, [surveyId, voter]);

  const removeRelationData = async (id) => {
    await removeRelation(
      id,
      () => {},
      () => {}
    );
    fetchRelationsData();
  };

  return (
    <MDBCard className="mt-2 pb-2">
      <MDBCardTitle
        style={{
          display: "flex",
          padding: 10,
          paddingLeft: 25,
          alignItems: "center",
          marginBottom: 0,
          justifyContent: "space-between",
        }}
      >
        <MDBIcon fas icon="person" />
        <strong className="ms-3">{relation} </strong>
        <Col className="button-right-group">
          <Button
            color="secondary"
            className=" ml-3"
            onClick={() => setShowSurveyModal(true)}
          >
            {voterSurvey && voterSurvey._id ? "Edit Survey" : "Add Survey"}
          </Button>
          <Button
            className="ms-2"
            size="sm"
            onClick={() => removeRelationData(relationId)}
          >
            <MDBIcon fas icon="times" size={15} />
          </Button>
        </Col>
      </MDBCardTitle>
      <MDBCardBody style={{ padding: 0 }}>
        <ResultCard
          voter={voter}
          onClick={() => navigate("/voter/" + voter._id)}
        />
      </MDBCardBody>

      <SurveyModal
        voterId={voter._id}
        surveyId={surveyId}
        modal={isSurveyModalShow}
        headId={relationHead}
        isShowCopy={true}
        fetchSurveyData={fetchSurveyData}
        setShowModal={setShowSurveyModal}
      />
    </MDBCard>
  );
}
